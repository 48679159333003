import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useFormHandlers from "../useFormHandlers";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useSettingDispatchChange } from "../index";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

const ShortChangeTransactionsValidation = Yup.object({
  shortchangeenabled: Yup.string().required(),
  shortchangeinstructions: Yup.string().max(
    500,
    "Text must be 500 characters or less"
  ),
});

const ShortChangeTransactionsForm = ({
  identifier,
  entityID,
  entityType,
  settingValues,
  disabled,
}) => {
  const classes = useStyles();
  const [shortChangeExpanded, setShortChangeExpanded] = useState(true);
  const [shortChangeEnabled, setShortChangeEnabled] = useState(
    settingValues.shortchangeenabled
  );
  const [shortChangeInstructions, setShortChangeInstructions] = useState(
    settingValues.shortchangeinstructions
  );
  const [errors, setErrors] = useState({});
  const { dispatchSetting } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();

  const { canRenderField } = useFormHandlers(
    undefined,
    settingValues,
    entityID,
    entityType
  );

  const toggleShortChangeExpanded = () => {
    setShortChangeExpanded(!shortChangeExpanded);
  };

  const onShortChangeEnableToggle = async e => {
    const targetValue = e.target.checked;
    if (targetValue == settingValues.shortchangeenabled) return;
    try {
      await ShortChangeTransactionsValidation.fields.shortchangeenabled.validate(
        targetValue
      );
      setErrors({ ...errors, shortchangeenabled: "" });
    } catch (e) {
      setErrors({ ...errors, shortchangeenabled: e.message });
      return;
    }
    setShortChangeEnabled(targetValue);
    if (!(await dispatchSetting(entityID, "shortchangeenabled", targetValue))) {
      setShortChangeEnabled(settingValues.shortchangeenabled);
      enqueueSnackbar("Failed to update short change active status", {
        variant: "error",
        tag: "FailedToUpdateShortChangeEnabled",
      });
    }
  };

  const onShortChangeInstructionsChange = async e => {
    const targetValue = e.target.value;
    if (targetValue == settingValues.shortchangeinstructions) return;
    setShortChangeInstructions(targetValue);
    try {
      await ShortChangeTransactionsValidation.fields.shortchangeinstructions.validate(
        targetValue
      );
      setErrors({ ...errors, shortchangeinstructions: "" });
    } catch (e) {
      setErrors({ ...errors, shortchangeinstructions: e.message });
      return;
    }
  };

  const onShortChangeInstructionsBlur = async e => {
    const targetValue = e.target.value;
    if (targetValue == settingValues.shortchangeinstructions) return;
    setShortChangeInstructions(targetValue);
    try {
      await ShortChangeTransactionsValidation.fields.shortchangeinstructions.validate(
        targetValue
      );
      setErrors({ ...errors, shortchangeinstructions: "" });
    } catch (e) {
      setErrors({ ...errors, shortchangeinstructions: e.message });
      return;
    }
    if (
      !(await dispatchSetting(entityID, "shortchangeinstructions", targetValue))
    ) {
      setShortChangeInstructions(settingValues.shortchangeinstructions);
      enqueueSnackbar("Failed to update short change instructions", {
        variant: "error",
        tag: "FailedToUpdateShortChangeInstructions",
      });
    }
  };

  return (
    <Grid className={clsx("short-change-panel", classes.panelRoot)} container data-testid={identifier}>
      <Accordion
        data-tag="shortChange"
        expanded={shortChangeExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={clsx("accordion", classes.fullWidthAccordion)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleShortChangeExpanded}
        >
          <Typography className={clsx("heading", classes.heading)}>
            Short Change Transactions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={6}
              className={clsx(classes.shortChangeTransactions)}
            >
              {canRenderField("shortchangeenabled") && (
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      id="shortchangeenabled"
                      name="shortchangeenabled"
                      checked={shortChangeEnabled}
                      data-checked={shortChangeEnabled}
                      className={clsx("toggle")}
                      disabled={disabled}
                      onChange={onShortChangeEnableToggle}
                      color="primary"
                    />
                  }
                  label={
                    <Typography>
                      Allow short change transactions at cash devices
                    </Typography>
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {canRenderField("shortchangeinstructions") && (
                <TextField
                  id="shortchangeinstructions"
                  className={clsx("text-box", classes.shortChangeInstructions)}
                  inputProps={{ "data-value": shortChangeInstructions }}
                  data-setting="shortChangeInstructions"
                  label="Short Change Instructions"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={shortChangeInstructions}
                  onChange={onShortChangeInstructionsChange}
                  onBlur={onShortChangeInstructionsBlur}
                  disabled={disabled}
                  error={Boolean(errors.shortchangeinstructions)}
                  helperText={errors.shortchangeinstructions}
                />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ShortChangeTransactionsForm.defaultProps = {
  settingValues: {},
};

export default ShortChangeTransactionsForm;
