import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  overlay: (props: any) => ({
    position: 'absolute',
    display: props.display,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ADADAD95',
    zIndex: 2,
    cursor: 'pointer',
    borderRadius: '4px'
  }),
  overlayContent: {
    position: 'absolute',
    top: '22%',
    left: '45%'
  },
  eye: {
    cursor: 'pointer',
  },
  disabledLabel: {
    color: theme.palette.text.disabled,
    fontStyle: "italic"
  },
}));