import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 1200;

export const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: 3
  },
  drawerItems: {
    padding: theme.spacing(1),
  },
  btnControl: {
    margin: theme.spacing(2),
    float: "right",
    right: '10px',
  },
  flexInput: {
    flexGrow: "1"
  },
  headerContainerSpacing: {
    marginTop: '15px'
  },
  reverseLaneDividerBottom: {
    marginBottom: '15px'
  },
  ConfigurationTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    color: theme.palette.primary.light,
  },
  reverseLaneDrawer: {
    display: "inline-block",
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  reverseLaneBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  drawerDetailConfiguration: {
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  reverseLaneDividerFixed: {
    marginBottom: '10px',
    marginRight: '20px',
    marginLeft: '20px',
  }
}));
