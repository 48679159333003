import React, { useState } from "react";
import { useFormik } from "formik";
import {
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Switch,
  MenuItem,
  TextField,
  Button,
  Tooltip
} from "@material-ui/core";
import * as Yup from "yup";
import { useStyles } from "./styles";
import useFormHandlers from "./useFormHandlers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import SettingsResetButton from "./SettingsResetButton";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import clsx from 'clsx';

const EmailSettingsValidation = Yup.object().shape({
  ccreplyto: Yup.boolean(),
  emaildisplayfrom: Yup.string()
    .required("Display Name is required")  
    .matches(/^[^\\"]*$/, 'Display Name cannot contain \\ and " characters'),      
  emailreply: Yup.string()
    .required("Reply To Email is required")
    .email("Invalid email"),
  emailtemplate: Yup.string().max(2000, "Maximum of 2000 characters"),
});

const EmailSettingsForm = ({
  entityID,
  entityType,
  settingValues,
  settingValuesParent,
  disabled,
}) => {
  const classes = useStyles();
  const [emailExpanded, setEmailExpanded] = useState(true);
  const [emailQueue, setEmailQueue] = useState({});
  const underFacilityGroup = useSelector((state) => {
      return state.entityScope?.facilityGroupId ? true : false;
    }
  );
  const theme = useTheme();
  const smallWindow = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    values,
    setFieldValue,
    initialValues,
    errors,
    handleChange,
  } = useFormik({
    initialValues: settingValues,
    validationSchema: EmailSettingsValidation,
  });
  const {
    handleToggle,
    handleInputChange,
    handleResetChange,
  } = useFormHandlers(setFieldValue, initialValues, entityID, entityType);

  const toggleEmailExpaned = () => {
    setEmailExpanded(!emailExpanded);
  };

  const emailInputChange = (errors, e) => {
    //we don't want to submit email settings if ANY of the fields have errors
    if (_.isEmpty(errors) || errors[e.target.name]) {
      for (const value in emailQueue) {
        handleInputChange(errors, emailQueue[value]);
      }
      handleInputChange(errors, e);
    } else {
      setEmailQueue({ ...emailQueue, [e.target.name]: { ...e } });
    }
  };

  const getStyle = (type) => {
    var isEqual;
    if (!underFacilityGroup) return clsx('fullWidth', classes.fullWidth);
    switch (type) {
      case "emaildisplay":
        isEqual = (values.emaildisplayfrom === settingValuesParent.emaildisplayfrom);
        break;
      case "emailreply":
        isEqual = (values.emailreply === settingValuesParent.emailreply);
        break
      case "emailtemplate":
        isEqual = (values.emailtemplate === settingValuesParent.emailtemplate);
        break;
    }
    return isEqual ? 
      clsx('fullWidth', classes.fullWidth) : clsx('withReset', classes.withReset);
  };

  return (
    <Grid className={classes.panelRoot} container>
      <Accordion
        data-tag="email"
        expanded={emailExpanded}
        TransitionProps={{ unmountOnExit: true }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleEmailExpaned}
        >
          <Typography className={classes.heading}>E-Mail</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid data-tag="ccreplyto" item xs={12} sm={4} md={4} lg={4} xl={4}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Switch
                    data-testid="ccreplyto"
                    name="ccreplyto"
                    checked={values.ccreplyto}
                    onChange={e => handleToggle(values, errors, e)}
                    disabled={
                      disabled ||
                      !values.emailreply ||
                      Boolean(errors.emailreply)
                    }
                    color="primary"
                  />
                }
                label="CC Reply Enabled"
              />
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="ccreplyto"
                currentValue={values.ccreplyto}
                originalValue={settingValuesParent.ccreplyto}
                onClick={() => {
                  handleResetChange(
                    "ccreplyto",
                    settingValuesParent.ccreplyto
                  );
                }}
              />
            </Grid>
            <Grid data-tag="emaildisplayfrom" item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Tooltip
                    title='Display name is used to add a familiar name so the recipient knows where the email came from. Example, when a recipient receives an email from the system, they will see  "Display Name <donotreply@amano-one.com>”'
                    placement="top-start"
                  >
                <TextField
                  data-testid="emaildisplay"
                  className={getStyle("emaildisplay")}
                  variant="outlined"
                  label="Display Name"
                  name="emaildisplayfrom"
                  onChange={handleChange}
                  value={values.emaildisplayfrom}
                  onBlur={e => emailInputChange(errors, e)}
                  error={Boolean(errors.emaildisplayfrom)}
                  helperText={errors.emaildisplayfrom}
                  disabled={disabled}
                  InputProps={{
                    readOnly: Boolean(disabled),
                    "aria-readonly": Boolean(disabled),
                    disabled: Boolean(disabled),
                  }}
                />
              </Tooltip>
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="emaildisplayfrom"
                currentValue={values.emaildisplayfrom}
                originalValue={settingValuesParent.emaildisplayfrom}
                onClick={() => {
                  handleResetChange(
                    "emaildisplayfrom",
                    settingValuesParent.emaildisplayfrom
                  );
                }}
              />
            </Grid>
            <Grid
              data-tag="emailreply"
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <TextField
                data-testid="emailreply"
                className={getStyle("emailreply")}
                variant="outlined"
                label="Reply To"
                name="emailreply"
                onChange={handleChange}
                value={values.emailreply}
                onBlur={e => emailInputChange(errors, e)}
                error={Boolean(errors.emailreply)}
                helperText={errors.emailreply || "reply-to email address"}
                disabled={disabled}
                InputProps={{
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                }}
              />
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="emailreply"
                currentValue={values.emailreply}
                originalValue={settingValuesParent.emailreply}
                onClick={() => {
                  handleResetChange(
                    "emailreply",
                    settingValuesParent.emailreply
                  );
                }}
              />
            </Grid>
            {!smallWindow && (
              <Grid
                data-tag="spacer"
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
              ></Grid>
            )}
            <Grid
              data-tag="emailtemplate"
              item
              xs={12}
              sm={8}
              md={8}
              lg={8}
              xl={8}
            >
              <TextField
                data-testid="emailtemplate"
                className={getStyle("emailtemplate")}
                name="emailtemplate"
                data-setting="emailtemplate"
                label={`Credential E-Mail Template ${
                  disabled ? " (disabled)" : ""
                }`}
                multiline
                rows={4}
                variant="outlined"
                value={values.emailtemplate}
                onChange={handleChange}
                onBlur={e => emailInputChange(errors, e)}
                error={Boolean(errors.emailtemplate)}
                helperText={errors.emailtemplate}
                disabled={disabled}
                InputProps={{
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                }}
              />
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="emailtemplate"
                currentValue={values.emailtemplate}
                originalValue={settingValuesParent.emailtemplate}
                onClick={() => {
                  handleResetChange(
                    "emailtemplate",
                    settingValuesParent.emailtemplate
                  );
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

EmailSettingsForm.defaultProps = {
  disabled: false,
};

export default EmailSettingsForm;
