import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSettingDispatchChange } from "./index";
import useFormHandlers from "./useFormHandlers";
import { useFormik } from "formik";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import * as Yup from "yup";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-duotone-svg-icons";
import SettingsResetButton from "./SettingsResetButton";

const TicketSettingsValidations = Yup.object().shape({
  ticket_footer: Yup.string(),
  ticket_header: Yup.string(),
  issueticketwhilefull: Yup.string(),
  // lostticketbuttonisenabled: Yup.string(),
});

const TicketSettingsForm = ({
  entityID,
  entityType,
  settingValues,
  settingValuesParent,  
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [ticketsExpanded, setTicketsExpanded] = useState(true);
  const {
    initialValues,
    values,
    errors,
    setFieldValue,
    handleChange,
  } = useFormik({
    initialValues: settingValues,
    validationSchema: TicketSettingsValidations,
  });
  const {
    handleToggle,
    canRenderField,
    handleResetChange,
    canDisplayResetIcon,
  } = useFormHandlers(setFieldValue, initialValues, entityID, entityType);
  const { dispatchSetting } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();

  const toggleTicketsExpanded = () => {
    setTicketsExpanded(!ticketsExpanded);
  };

  return (
    <Grid className={clsx("tickets-panel", classes.panelRoot)} data-testid="Ticket-settings-form" container>
      <Accordion
        data-tag="ticket"
        expanded={ticketsExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={clsx("accordion", classes.fullWidthAccordion)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleTicketsExpanded}
        >
          <Typography className={clsx("heading", classes.heading)}>
            Tickets
          </Typography>
          <Typography
            className={clsx("secondary-heading", classes.secondaryHeading)}
          ></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              data-tag="issueticketwhilefull"
              className={clsx("issue-ticket-while-full")}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              {canRenderField("issueticketwhilefull") && (
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      label="Issue Ticket While Full"
                      name="issueticketwhilefull"
                      checked={values.issueticketwhilefull}
                      data-checked={values.issueticketwhilefull}
                      className={clsx("toggle")}
                      onChange={e => handleToggle(values, errors, e)}
                      color="primary"
                    />
                  }
                  label="Issue Ticket While Full"
                />
              )}
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="issueticketwhilefull"
                currentValue={values.issueticketwhilefull}
                originalValue={settingValuesParent.issueticketwhilefull}
                onClick={() => {
                  handleResetChange(
                    "issueticketwhilefull",
                    settingValuesParent.issueticketwhilefull
                  );
                }}
              />
            </Grid>
            <Grid
              data-tag="lostticketbuttonisenabled"
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              {/* {LostTicketPermission && (
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      label="Lost Ticket Enabled"
                      name="lostticketbuttonisenabled"
                      checked={values.lostticketbuttonisenabled}
                      onChange={(e) => handleToggle(values, errors, e)}
                    />
                  }
                  label="Lost Ticket Enabled"
                />
              )} */}
            </Grid>
            <Grid
              data-tag="ticket.header"
              className={clsx("ticker-header")}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              {canRenderField("ticket.header") && (
                <TextField
                  style={{
                    width:
                      values.ticket_header != settingValuesParent.ticket_header
                        ? "calc(100% - 25px)"
                        : "100%",
                  }}
                  id="ticket_header"
                  className={clsx("text-box")}
                  inputProps={{ "data-value": values.ticket_header }}
                  data-setting="ticket_header"
                  label="Ticket Header"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.ticket_header}
                  onChange={handleChange}
                  onBlur={async e => {
                    let targetValue = e.target.value;
                    if (targetValue == initialValues.ticket_header) return; //if its the same, lets not, shall we?
                    if (errors.ticket_header) {
                      setFieldValue(
                        "ticket_header",
                        initialValues.ticket_header
                      );
                      return;
                    }
                    //if we weren't successful in dispatching, revert value
                    if (
                      !(await dispatchSetting(
                        entityID,
                        "ticket.header",
                        targetValue
                      ))
                    ) {
                      setFieldValue(
                        "ticket_header",
                        initialValues.ticket_header
                      );
                      enqueueSnackbar("Failed to update ticket header", {
                        variant: "error",
                        tag: "FailedToUpdateTicketHeader",
                      });
                    }
                  }}
                  error={Boolean(errors.ticket_header)}
                  helperText={errors.ticket_header}
                />
              )}
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="ticket.header"
                currentValue={values.ticket_header}
                originalValue={settingValuesParent.ticket_header}
                onClick={() => {
                  setFieldValue(
                    "ticket_header",
                    settingValuesParent.ticket_header
                  );
                  handleResetChange(
                    "ticket.header",
                    settingValuesParent.ticket_header
                  );
                }}
              />
            </Grid>
            <Grid
              data-tag="ticket.footer"
              className={clsx("ticket-footer")}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              {canRenderField("ticket.footer") && (
                <TextField
                  style={{
                    width:
                      values.ticket_footer != settingValuesParent.ticket_footer
                        ? "calc(100% - 25px)"
                        : "100%",
                  }}
                  id="ticket_footer"
                  data-setting="ticket_footer"
                  label="Ticket Footer"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.ticket_footer}
                  onChange={handleChange}
                  className={clsx("text-box")}
                  inputProps={{ "data-value": values.ticket_footer }}
                  onBlur={async e => {
                    let targetValue = e.target.value;
                    if (targetValue == initialValues.ticket_footer) return; //if its the same, lets not, shall we?
                    if (errors.ticket_footer) {
                      setFieldValue(
                        "ticket_footer",
                        initialValues.ticket_footer
                      );
                      return;
                    }
                    //if we weren't successful in dispatching, revert value
                    if (
                      !(await dispatchSetting(
                        entityID,
                        "ticket.footer",
                        targetValue
                      ))
                    ) {
                      setFieldValue(
                        "ticket_footer",
                        initialValues.ticket_footer
                      );
                      enqueueSnackbar("Failed to update ticket footer", {
                        variant: "error",
                        tag: "FailedToUpdateTicketFooter",
                      });
                    }
                  }}
                  error={Boolean(errors.ticket_footer)}
                  helperText={errors.ticket_footer}
                />
              )}
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="ticket.footer"
                currentValue={values.ticket_footer}
                originalValue={settingValuesParent.ticket_footer}
                onClick={() => {
                  setFieldValue(
                    "ticket_footer",
                    settingValuesParent.ticket_footer
                  );
                  handleResetChange(
                    "ticket.footer",
                    settingValuesParent.ticket_footer
                  );
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

TicketSettingsForm.defaultProps = {
  settingValues: {},
};

export default TicketSettingsForm;
