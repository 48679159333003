import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useFormHandlers from "../useFormHandlers";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import SettingsResetButton from "../SettingsResetButton";
import { useSettingDispatchChange } from "../index";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import ReceiptLines from "./ReceiptLines";

const PrinterSettingsValidation = Yup.object().shape({
  // printerretracttimeout: Yup.number()
  //   .typeError("Must be a number")
  //   .integer("Must be a whole number")
  //   .positive("Must be greater than zero")
  //   .required("Required"),
  autoprintreceipt: Yup.string().required(),
  receipt_header: Yup.string()
    .max(36, "Each Line Should be a Max of 36 Characters")
    .optional(),
  receipt_footer: Yup.string()
    .max(36, "Each Line Should be a Max of 36 Characters")
    .optional(),
  receiptHeaderLines: Yup.array()
    .of(Yup.string().max(36, "Each Line Should be a Max of 36 Characters"))
    .optional(),
  receiptFooterLines: Yup.array()
    .of(Yup.string().max(36, "Each Line Should be a Max of 36 Characters"))
    .optional(),
});

const PrinterSettingsForm = ({
  entityID,
  entityType,
  settingValues,
  settingValuesParent,  
}) => {
  const classes = useStyles();
  const [printerExpanded, setPrinterExpanded] = useState(true);
  const [receiptHeaderLines, setReceiptHeaderLines] = useState(
    settingValues.receipt_header?.split("\n")
  );
  const [receiptFooterLines, setReceiptFooterLines] = useState(
    settingValues.receipt_footer?.split("\n")
  );
  const { dispatchSetting } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { values, errors, setFieldValue, initialValues } = useFormik({
    initialValues: settingValues,
    validationSchema: PrinterSettingsValidation,
  });
  const { handleToggle, canRenderField, handleResetChange } = useFormHandlers(
    setFieldValue,
    initialValues,
    entityID,
    entityType
  );

  const togglePrinterExpanded = () => {
    setPrinterExpanded(!printerExpanded);
  };

  const handleReceiptLinesSubmit = async (newLines, fieldName) => {
    const setLinesFunction =
      fieldName.toLowerCase() === "footer"
        ? setReceiptFooterLines
        : setReceiptHeaderLines;
    setLinesFunction(newLines);
    if (
      !(await dispatchSetting(
        entityID,
        `receipt.${fieldName}`,
        newLines.join("\n")
      ))
    ) {
      enqueueSnackbar(`Failed to update receipt ${fieldName}`, {
        variant: "error",
        tag: "FailedToUpdateReceipt",
      });
    }
  };

  return (
    <Grid className={clsx("printing-panel", classes.panelRoot)} data-testid="printer-settings-form" container>
      <Accordion
        data-tag="printer"
        expanded={printerExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={clsx("accordion", classes.fullWidthAccordion)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={togglePrinterExpanded}
        >
          <Typography className={clsx("heading", classes.heading)}>
            Receipt
          </Typography>
          <Typography
            className={clsx("secondary-heading", classes.secondaryHeading)}
          ></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid
              data-tag="autoprintreceipt"
              className={clsx("auto-print-receipt")}
              item
              xs={12}
            >
              {canRenderField("autoprintreceipt") && (
                <>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Switch
                        name="autoprintreceipt"
                        checked={values.autoprintreceipt}
                        data-checked={values.autoprintreceipt}
                        className={clsx("toggle")}
                        onChange={e => handleToggle(values, errors, e)}
                        color="primary"
                      />
                    }
                    label={<Typography>Auto Print Receipt</Typography>}
                  />
                  <SettingsResetButton
                    entityID={entityID}
                    entityType={entityType}
                    settingName="autoprintreceipt"
                    currentValue={values.autoprintreceipt}
                    originalValue={settingValuesParent.autoprintreceipt}
                    onClick={() => {
                      handleResetChange(
                        "autoprintreceipt",
                        settingValuesParent.autoprintreceipt
                      );
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid
              data-tag="receipt.header"
              className={clsx("receipt-header")}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              {canRenderField("receipt.header") && (
                <ReceiptLines
                  lines={receiptHeaderLines}
                  label="Header"
                  settingName="receipt.header"
                  onSubmit={async newLines => {
                    await handleReceiptLinesSubmit(newLines, "header");
                  }}
                  entityID={entityID}
                  entityType={entityType}
                  parentValueLines={settingValuesParent.receipt_header.split(
                    "\n"
                  )}
                />
              )}
            </Grid>
            <Grid
              data-tag="receipt.footer"
              className={clsx("receipt-footer")}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              {canRenderField("receipt.footer") && (
                <ReceiptLines
                  lines={receiptFooterLines}
                  label="Footer"
                  settingName="receipt.footer"
                  onSubmit={async newLines => {
                    await handleReceiptLinesSubmit(newLines, "footer");
                  }}
                  entityID={entityID}
                  entityType={entityType}
                  parentValueLines={settingValuesParent.receipt_footer.split(
                    "\n"
                  )}
                />
              )}
            </Grid>
            {/* <Grid
              data-tag="printerretracttimeout"
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              { canRenderField("printerretracttimeout") && (
                <TextField
                  variant="outlined"
                  label="Printer Retract Time-Out"
                  name="printerretracttimeout"
                  onChange={handleChange}
                  value={values.printerretracttimeout}
                  onBlur={(e) => handleInputChange(errors, e)}
                  error={Boolean(errors.printerretracttimeout)}
                  helperText={errors.printerretracttimeout || "in milliseconds"}
                />
              )}
            </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

PrinterSettingsForm.defaultProps = {
  settingValues: {},
};

export default PrinterSettingsForm;
