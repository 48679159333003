export const initialState = {
  settings: {
    valetPropertyId: {
      name: 'valetpropertyid',
      label: 'Property ID'
    },
    valetStartTicket: {
      name: 'valetstartticket',
      label: 'Ticket Start Number'
    },
    valetEndTicket: {
      name: 'valetendticket',
      label: 'Ticket End Number'
    },
    valetApiUrl: {
      name: 'valetapiurl',
      label: 'API URL'
    },
    valetApiUsername: {
      name: 'valetapiusername',
      label: 'API Username'
    },
    valetApiPassword: {
      name: 'valetapipassword',
      label: 'API Password'
    }
  },
  isAccordionExpanded: true,
  isPasswordMasked: true
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SETTINGS': {
      return {
        ...state,
        settings: action.payload
      }
    }
    case 'SET_IS_ACCORDION_EXPANDED': {
      return {
        ...state,
        isAccordionExpanded: action.payload
      }
    }
    case 'SET_IS_PASSWORD_MASKED': {
      return {
        ...state,
        isPasswordMasked: action.payload
      }
    }
  }
}