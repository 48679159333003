import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  credentialcheckbox: {
    transform: "scale(1.5)",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
  unlimitedcheckbox:{
    transform: "scale(1.5)",
    margin: theme.spacing(2)
  },
  dataRetention: {
    marginLeft: theme.spacing(2),
  },
  slider: {
    marginTop: theme.spacing(5),
  },
  unlimited: {
    marginLeft: theme.spacing(4),
  },
  textRetention: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  ticketLessFee: { 
    marginTop: theme.spacing(1)
  },
  ticketLessFeeDelay: { 
    marginTop: theme.spacing(3)
  },
  resetButton: {
    display: "inline-flex",
    width: "100%"
  },
  entrycapturemodeSelector: {
    width: "28em !important" 
  }
}));