import React from "react";

/* Components */
import { Button, FormControlLabel, Grid, Switch } from "@material-ui/core";

/* Utilities */
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

/* Form State */
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSettingDispatchChange } from "..";

/* Style */
import clsx from "clsx";

export const DoorDeviceSchema = Yup.object().shape({});

const DoorDeviceForm = ({
  entityID,
  settingValues,
  onRatesClicked,
  onPeripheralsClicked,
  onGPIOClicked,
}) => {
  const { hasPermissions } = useHasPermissions();
  const RatesViewPermission = hasPermissions(["rates.view"], false, entityID);
  const { dispatchSetting } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();

  const { control } = useForm({
    resolver: yupResolver(DoorDeviceSchema),
    mode: "onChange",
    defaultValues: {
      deviceDisabled: settingValues?.devicedisabled ?? false,
    },
  });

  const handleDeviceDisabledChanged = async (e) => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues?.deviceDisabled) return;
    if (!(await dispatchSetting(entityID, "devicedisabled", targetValue)))
      enqueueSnackbar("Unable to disable Door Device", {
        variant: "error",
        tag: "DoorDeviceDisabled",
      });
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Controller
          name="deviceDisabled"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              role="device-disabled-toggle"
              label="Disabled"
              control={
                <Switch
                  {...field}
                  id="deviceDisabled"
                  color="primary"
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    handleDeviceDisabledChanged(e);
                  }}
                  checked={field.value}
                  data-checked={field.value}
                />
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
        {RatesViewPermission && (
          <Button
            className={clsx(["btn-rates"])}
            fullWidth
            variant="contained"
            onClick={onRatesClicked}
            color="primary"
          >
            Rates
          </Button>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
        <Button
          className={clsx(["peripherals"])}
          variant="contained"
          fullWidth
          onClick={onPeripheralsClicked}
          color="primary"
        >
          Peripherals
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
        <Button
          className={clsx(["gpio"])}
          variant="contained"
          fullWidth
          name="GPIO"
          onClick={onGPIOClicked}
          color="primary"
        >
          GPIO
        </Button>
      </Grid>
    </>
  );
};

export default DoorDeviceForm;
