import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/core/styles";

import ConvenienceFee from "../../ConvenienceFee/ConvenienceFee";

const MobilePaySettingsForm = ({
  entityID,
  entityType,
  settingValues,
  settingValuesParent,
  disabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobilePayExpanded, setMobilePayExpanded] = useState(true);

  const toggleMobilePayExpanded = () => {
    setMobilePayExpanded(!mobilePayExpanded);
  };

  return (
    <Grid container className={classes.panelRoot}>
      <Accordion
        data-tag="mobilePay"
        expanded={mobilePayExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleMobilePayExpanded}
        >
          <Typography className={classes.heading}>Mobile Pay</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {!disabled && (
              <Grid
                data-tag="conveneienceFee"
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <ConvenienceFee entityID={entityID} />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

MobilePaySettingsForm.defaultProps = {
  settingValues: {},
};

export default MobilePaySettingsForm;
