import { darken, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  offerContainer: {
    display: "inline-flex",
    wdith: "100% !important"
  },
  poeSelector: {
    width: "100% !important",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
  resetPoeWheel: {
    marginLeft: theme.spacing(2),
  },
  calendarIconButtonText: {
    color: darken(theme.palette.amano.main, 0.2),
    flex: 2,
    fontSize: "32px",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
  },
  drawer: {
  },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: "40%",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
}));
