import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useSelector } from "react-redux";

/**
 *
 * @param {Function} equality used to override the default equality comparison of setting values
 * @returns bool
 */
const SettingsResetButton = ({
  entityID,
  entityType,
  settingName,
  currentValue,
  originalValue,
  onClick,
  equality,
  ...props
}) => {
  const theme = useTheme();

  const underFacilityGroup = useSelector((state) => {
      return state.entityScope?.facilityGroupId ? true : false;
    }    
  );

  const TooltipLight = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const isEqual = (settingA, settingB) => {
    if (equality) {
      return equality(settingA, settingB);
    } else return settingA == settingB;
  };

  const canDisplayResetIcon = () => {
    if (
      entityType?.toLowerCase() === "device" &&
      !isEqual(currentValue, originalValue)
    ) {
      switch (settingName.toLowerCase()) {
        case "displaylotfull":
        case "allowtransactionswhilegateraised":
        case "backouttimeout":
        case "toidletimeout":
        case "centralpaytrxtimeout":
        case "autoprintreceipt":
        case "issueticketwhilefull":
        case "ticket.header":
        case "ticket.footer":
        case "receipt.header":
        case "receipt.footer":
        case "duplicatecredentialreaddelay":
        case "intercomprovider":
        case "poe.enabled":
        case "poe.attendedmode":
        case "poe.showticketbutton":
        case "poeoffers":
        case "intercom.continuousring":
        case "lpr.confidencescore":
        case "lpr.matchaccuracy":
        case "lpr.credential":
        case "lpr.calculatefee":
        case "lpr.hotelguestautoenroll":
        case "lpr.ticketless":
        case "lpr.ticketlessexceptionfeedelay":
        case "lpr.ticketlessexceptionfeeduration":
        case "lpr.ticketlessentrycapturemode":
          return true;
        default:
          return false;
      }
    } else if (
      entityType?.toLowerCase() === "facility" &&
      underFacilityGroup &&
      !isEqual(currentValue, originalValue)
    ) {
      switch (settingName.toLowerCase()) {        
        case "ccreplyto":
        case "emaildisplayfrom":
        case "emailreply":
        case "emailtemplate":
        case "restapiuser":
        case "restapikey":
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  useEffect(() => {
    // if currentValue changes
    // console.log("reset button", entityType, settingName, currentValue, originalValue)
    // console.log("can reset", canDisplayResetIcon());

    return () => {};
  }, [currentValue]);

  return (
    <>
      {canDisplayResetIcon() && (
        <>
          <TooltipLight title="Click to Reset">
            <RotateLeftIcon
              {...props}
              data-testid={`reset-${settingName}`}
              aria-label="Click to Reset"
              style={{
                color: theme.palette.warning.dark,
                cursor: "pointer",
                verticalAlign: "middle",
                ...props.style,
              }}
              onClick={onClick}
            />
          </TooltipLight>
        </>
      )}
    </>
  );
};

SettingsResetButton.defaultProps = {
  entityID: null,
  entityType: null,
  settingName: null,
  currentValue: null,
  originalValue: null,
  onClick: () => {},
};

SettingsResetButton.propTypes = {
  equality: PropTypes.func,
};

export default SettingsResetButton;
