import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  intercomProviderSelector: {
    width: "25em !important",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
  intercomSettingContainer: {
    display: "inline-flex",
  },
}));
