import React, { useCallback, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  Tooltip
} from "@material-ui/core";
import * as Yup from "yup";
import { useStyles } from "./styles";
import {useStyles as useRootStyles} from "../styles";
import useFormHandlers from "../useFormHandlers";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useHasPermissions from "../../../../hooks/useHasPermissions";

export const AreaSettingsValidation = Yup.object().shape({
  duplicatecredentialreaddelay: Yup.number()
    .typeError("Must be a number")
    .integer("Must be a whole number")
    .positive("Must be greater than 1000 milliseconds")
    .moreThan(1000, "Must be greater than 1000 milliseconds")
    .lessThan(15000, "Must be less than 15000 milliseconds")
    .required("Required"),
  backouttimeout: Yup.number()
    .typeError("Must be a number")
    .integer("Must be a whole number")
    .positive("Must be greater than zero")
    .required("Required"),
});

const AreaSettingsForm = ({
  entityID,
  entityType,
  settingValues
}) => {
 
  
  const classes = useStyles();
  const rootClasses = useRootStyles();
  const [deviceExpanded, setDeviceExpanded] = useState(true);
  const { hasPermissions } = useHasPermissions(); 
  const EditAreaSettingsPermission = hasPermissions(
    ["overview.area.editsettings"],
    false,
    entityID
  );

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AreaSettingsValidation),
    defaultValues: {
      backouttimeout: settingValues.backouttimeout,
      duplicatecredentialreaddelay: settingValues.duplicatecredentialreaddelay,
    },
  });

  const { handleInputChange } = useFormHandlers(
    setValue,
    settingValues,
    entityID,
    entityType
  );

  const toggleDeviceExpanded = useCallback(() => {
    setDeviceExpanded(prevState => !prevState);
  }, []);

  return (
    <Grid className={rootClasses.panelRoot} container>
  {EditAreaSettingsPermission && (
    <>
      <Accordion
        data-tag="devices"
        expanded={deviceExpanded}
        TransitionProps={{ unmountOnExit: true }}
        className={rootClasses.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          data-testid="devices-accordian"
          onClick={toggleDeviceExpanded}
        >
          <Typography className={rootClasses.heading}>Devices</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid
          data-tag="backouttimeout"
          item
          xs={12}  
        >
            <Controller
              name="backouttimeout"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  className={classes.inputFeild}
                  label="Backout Timeout"
                  id="backouttimeout"
                  data-testid="backout-timeout-input"
                  onBlur={e => handleInputChange(errors, e)}
                  error={!!errors.backouttimeout}
                  helperText={
                    (errors.backouttimeout && errors.backouttimeout.message) || "in milliseconds"}
                />
              )}
          />
          </Grid>
        <Grid
          data-tag="credential"
          item
          xs={12}
          >
          <Tooltip
            placement="top-end"
            title="Number of milliseconds to ignore duplicate credential reads"
          >
              <Controller
                name="duplicatecredentialreaddelay"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    className={classes.inputFeild}
                    label="Duplicate Credential Read Delay"
                    id="duplicatecredentialreaddelay"
                    data-testid="duplicate-credential-read-delay-input"
                    onBlur={e => handleInputChange(errors, e)}
                    error={!!errors.duplicatecredentialreaddelay}
                    helperText={
                    (errors.duplicatecredentialreaddelay && errors.duplicatecredentialreaddelay.message) || "in milliseconds"
                  }
                  />
                )}
            />
          </Tooltip>
        </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )}
    </Grid>
  );
};

AreaSettingsForm.defaultProps = {
  settingValues: {},
};

export default AreaSettingsForm;
