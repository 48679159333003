import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import TerminalInterfaceForm from "./TerminalInterfaceForm";
import HotelInterfaceForm from "./HotelInterfaceForm";
import * as Yup from "yup";
import { useStyles } from "../styles";
import useFormHandlers from "../useFormHandlers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DEVICE_MODE } from "../../../../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlags";
import DoorDeviceForm from "./DoorDeviceForm";

import apiClient from "../../../../auth/apiClient";
import ReversingLaneService from "../../../../services/ReversingLaneService";
const reversingLaneService = new ReversingLaneService(apiClient);


const DeviceSettingsForm = ({
  entityID,
  entityType,
  settingValues,
  onTaxesClicked,
  onRatesClicked,
  onPeripheralsClicked,
  onOccupancyClicked,
  onGPIOClicked,
  onCCGatewayClicked,
  onCardFormatsClicked,
  onTaxSettingsClicked,
  settingValuesParent,
  onReversingLanesClicked,
  onHotelRatesClicked,
  onLogosAndBannersClicked
}) => {

  const DeviceSettingsValidation = Yup.object().shape({
    devicemode: Yup.string()
    .required()
    .test("getReversingLanesByDeviceID",
      "This device’s mode cannot be changed while paired as a reversing lane. Please delete the reversing lane pairing first.",
      (value) => {
      if (value === settingValues["devicemode"]) {
        return true;
      } else {
        return reversingLaneService.getReversingLanesByDeviceID(entityID)
        .then((res) => { 
          return (res?.data?.length == 0);
        })
        .catch(() => {
          return false;
        });
      }        
    }),
  });
  
  const classes = useStyles();
  const [deviceExpanded, setDeviceExpanded] = useState(true);
  const hotelIntegration = useFeatureFlag("Hotel Integrations");
  const { doorDeviceMode } = useFlags();

  const { values, setFieldValue, initialValues, errors} = useFormik({
    initialValues: settingValues,
    validationSchema: DeviceSettingsValidation,
  });

  const deviceMode = values?.devicemode?.toLowerCase();

  const { handleSelectChange, canRenderFieldByMode } = useFormHandlers(
    setFieldValue,
    initialValues,
    entityID,
    entityType
  );

  const terminalInterfaceFormProps = {
    entityID,
    entityType,
    settingValues,
    onTaxesClicked,
    onRatesClicked,
    onPeripheralsClicked,
    onOccupancyClicked,
    onGPIOClicked,
    onCCGatewayClicked,
    onCardFormatsClicked,
    onTaxSettingsClicked,
    settingValuesParent,
    onReversingLanesClicked,
    onLogosAndBannersClicked
  };

  const hotelInterfaceFormProps = {
    entityID,
    entityType,
    settingValues,
    onHotelRatesClicked,
  };

  const doorDeviceFormProps = {
    entityID,
    settingValues,
    onRatesClicked,
    onPeripheralsClicked,
    onGPIOClicked,
  };

  const handleDeviceMode = async (errors, e, selected) => {
    await handleSelectChange(errors, e, selected);
  };

  const toggleDeviceExpanded = () => {
    setDeviceExpanded(!deviceExpanded);
  };

  return (
    <Grid className={classes.panelRoot} container>
      <Accordion
        data-tag="devices"
        expanded={deviceExpanded}
        TransitionProps={{ unmountOnExit: true }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleDeviceExpanded}
        >
          <Typography className={classes.heading}>Devices</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {canRenderFieldByMode("devicemode", deviceMode) && (
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  data-id="device-mode"
                  data-testid = "device-mode-input"
                  id="device-mode"
                  name="devicemode"
                  label="Device Mode"
                  select
                  SelectProps={{
                    SelectDisplayProps: { "data-testid": "device-mode" },
                  }}
                  fullWidth
                  onChange={(e, selected) => {
                    setFieldValue(e.target.name, e.target.value)
                      .then((resErrors)=> {
                        if (!(resErrors && resErrors["devicemode"]))
                          handleDeviceMode(resErrors, e, selected);
                    })
                  }}
                  onBlur={e => {
                    if (errors && errors["devicemode"])
                      setFieldValue(e.target.name, initialValues["devicemode"])
                  }}
                  defaultValue={values["devicemode"] ?? ""}
                  value={values["devicemode"] ?? ""}
                  error={errors && errors["devicemode"] ? true : false}
                  helperText={errors && errors["devicemode"]}
                >
                  <MenuItem value="Entry">Entry</MenuItem>
                  <MenuItem value="Exit">Exit</MenuItem>
                  <MenuItem value="Kiosk">Pay on Foot</MenuItem>
                  <MenuItem value="Diagnostic">Diagnostic</MenuItem>
                  {doorDeviceMode && <MenuItem value="Door">Door</MenuItem>}
                  {hotelIntegration && (
                    <MenuItem value="Hotel Interface">Hotel Interface</MenuItem>
                  )}
                </TextField>
              </Grid>
            )}
            {(() => {
              switch (deviceMode?.toLowerCase()) {
                case DEVICE_MODE.HOTEL_INTERFACE.toLowerCase():
                  return <HotelInterfaceForm {...hotelInterfaceFormProps} />;
                case DEVICE_MODE.DOOR.toLowerCase():
                  return <DoorDeviceForm {...doorDeviceFormProps} />;
                default:
                  return (
                    <TerminalInterfaceForm {...terminalInterfaceFormProps} />
                  );
              }
            })()}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

DeviceSettingsForm.defaultProps = {
  settingValues: {},
  onTaxesClicked: () => {},
  onRatesClicked: () => {},
  onOccupancyClicked: () => {},
  onCCGatewayClicked: () => {},
  onCardFormatsClicked: () => {},
};

export default DeviceSettingsForm;
