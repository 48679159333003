import { makeStyles } from "@material-ui/core/styles";
import background from "../../../../assets/OfflineFunctionalityPreview.svg";

export const useStyles = makeStyles((theme) => ({
  imgContainer: {
    height: "525px",
    backgroundImage: `url(${background})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "325px",
  },
  container: {
    paddingTop: "25px",
    margin: 0
  },
  toolbar: {
    position: "relative",
    textAlign: "center",
  },
  editorContainer: {
    maxHeight: "390px !important",
    paddingLeft: "20px",
    paddingRight: "20px",
    overflow: "hidden",
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    width: "100px"
  },
  ticketExitFunctionDropdown: {
    paddingTop: "20px"
  },
  deviceMessageGrid: {
    display: "flex",
    justifyContent: "center"
  }
}));
