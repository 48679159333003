import React, { useState } from "react";
import { useStyles as useRootStyles } from "../styles";
import { useStyles } from "./styles";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, FormControlLabel, Switch, TextField, MenuItem, Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useFormHandlers from "../useFormHandlers";
import * as Yup from "yup";
import { OFFLINE_TICKET_EXIT_FUNCTION, OFFLINE_TICKET_EXIT_FUNCTION_VALUES } from "../../../../constants";
import RichTextEditor from "../../../MUIRichTextEditor";
import {Controller, useForm} from "react-hook-form";
import clsx from "clsx";
import PropTypes from "prop-types";

export const offlineFunctionalitySchema = Yup.object().shape({
    offlinefunctionalityenabled: Yup.bool(),
    exitofflinemessage: Yup.string()
        .min(2, "Message must be at least 2 characters")
        .max(50, "Message must be at most 50 characters")
        .required("Message is required"),
  });

const FacilityOfflineFunctionalitySettingsForm = ({entityID, entityType, settingValues}) => {
    const rootClasses = useRootStyles();
    const classes = useStyles();
    const [offlineFunctionalityExpanded, setOfflineFunctionalityExpanded] = useState(true);
    const [isDeviceMessageChanged, setIsDeviceMessageChanged] = useState(false);

    const { control, formState: { errors }, setValue, getValues, watch} = useForm({
        defaultValues: {
            offlinefunctionalityenabled: settingValues.offlinefunctionalityenabled,
            offlineticketexitfunction: settingValues.offlineticketexitfunction,
            exitofflinemessage: settingValues.exitofflinemessage
        },
        validationSchema: offlineFunctionalitySchema,
    });

    const isOfflineFunctionalityEnabled = watch("offlinefunctionalityenabled");

    const { handleToggle, handleBulkSettingUpdate, handleInputSave, handleSelectChange } = useFormHandlers(setValue, settingValues, entityID, entityType);

    const toggleOfflineFunctionalityExpanded = () => {
        setOfflineFunctionalityExpanded(!offlineFunctionalityExpanded);
    }

    const handleRichTextSave = (text) => {
        setValue("exitofflinemessage", text)
        canEnableSaveButton();
    };

    const handleDeviceMessageSave = () => {
        handleInputSave('exitofflinemessage', getValues('exitofflinemessage'))
    } 

    const canEnableSaveButton = () => {
        const defaultMessage = settingValues.exitofflinemessage;
        const updatedMessage = getValues('exitofflinemessage');
        const updatedElement = new DOMParser().parseFromString(updatedMessage, 'text/html').body.firstChild;
        const updatedTextContent = updatedElement.textContent.trim();

        if((defaultMessage === updatedMessage) || !updatedTextContent || !getValues("offlinefunctionalityenabled")) {
            setIsDeviceMessageChanged(false);
        } else {
            setIsDeviceMessageChanged(true);
        }
    }

    const updateBulkSettings = async (event) => {
        const enableOfflineFunctionality = event.target.checked;
  
        setValue("offlinefunctionalityenabled", enableOfflineFunctionality);
      
        if (enableOfflineFunctionality) {
          const targetNameList = ["offlinefunctionalityenabled", "offlineticketexitfunction", "exitofflinemessage"];
          const values = { ...settingValues, [event.target.name]: enableOfflineFunctionality };
          if (!(await handleBulkSettingUpdate(targetNameList, values))) {
            setValue("offlinefunctionalityenabled", !enableOfflineFunctionality);
          }
        } else {
          handleToggle({ offlinefunctionalityenabled: !enableOfflineFunctionality }, {}, event);
        }
    };

    return(
        <Grid className={rootClasses.panelRoot} container>
            <Accordion
                data-tag="offline-functionality"
                className={rootClasses.fullWidthAccordion}
                TransitionProps={{ unmountOnExit: true }}
                expanded={offlineFunctionalityExpanded}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    onClick={toggleOfflineFunctionalityExpanded}
                    data-testid="offline-settings-accordion"
                >
                    <Typography className={rootClasses.heading}>
                        Offline Functionality
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs>
                                <FormControlLabel
                                    role="enable-offline-functionality-toggle"
                                    label="Enable Offline Functionality"
                                    control={
                                        <Controller
                                            name="offlinefunctionalityenabled"
                                            control={control}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    color="primary"
                                                    onChange={e => updateBulkSettings(e)}
                                                    checked={field.value}
                                                    data-checked={field.value}
                                                    inputProps={{"aria-label": "offlinefunctionalityenabledswitch","data-testid": "offlinefunctionalityenabled-switch"}}
                                                />
                                            )}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs className={classes.ticketExitFunctionDropdown}>
                                <Controller
                                    name="offlineticketexitfunction"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={<Typography>Offline Ticket Exit Function</Typography>}
                                            select
                                            variant="outlined"
                                            inputProps={{
                                                ["data-testid"]: "offlineticketexitfunction-select",
                                            }}
                                            value={field.value}
                                            name="offlineticketexitfunction"
                                            onChange={(e, selected) =>
                                                handleSelectChange(errors, e, selected)
                                            }
                                            disabled={!isOfflineFunctionalityEnabled}
                                        >
                                            {Object.keys(OFFLINE_TICKET_EXIT_FUNCTION).map((key) => (
                                                <MenuItem key={key} value={OFFLINE_TICKET_EXIT_FUNCTION_VALUES[key]}>
                                                    {OFFLINE_TICKET_EXIT_FUNCTION[key]}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {(Number(getValues("offlineticketexitfunction")) === OFFLINE_TICKET_EXIT_FUNCTION_VALUES.DisplayOfflineMessage) && (
                            <Grid item xs={12} sm={6}>
                                    <Grid item xs className={classes.deviceMessageGrid}>
                                        <div className={clsx("offlineDeviceMessageEditor",
                                            classes.imgContainer,
                                        )}>
                                            <RichTextEditor
                                                disabled={!getValues("offlinefunctionalityenabled")}
                                                label="Enter device message"
                                                onChange={handleRichTextSave}
                                                content={settingValues.exitofflinemessage}
                                                customEditorStyles={classes}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs className={classes.deviceMessageGrid}>
                                        <Button
                                            className={clsx(["btn-submit", classes.submitBtn])}
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            onClick={handleDeviceMessageSave}
                                            disabled={!isDeviceMessageChanged}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

FacilityOfflineFunctionalitySettingsForm.defaultProps = { 
    entityID: "",
    entityType: "",
    settingValues: {}
}

FacilityOfflineFunctionalitySettingsForm.propTypes = { 
    entityID: PropTypes.string, 
    entityType: PropTypes.string,
    settingValues: PropTypes.any
}

export default FacilityOfflineFunctionalitySettingsForm