import React, { useState, useEffect } from 'react'
import { useStyles } from "./MaintenanceWindowSettingStyles";
import HelpIcon from '@material-ui/icons/Help';
import { Tooltip, Typography, Switch } from '@material-ui/core';
import { MaintenanceWindowForm } from './MaintenanceWindowForm';
import apiClient from "../../../../auth/apiClient";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";
import MaintenanceService from "../../../../services/MaintenanceWindowService";
import Loading from "../../../Loading";

const service = new MaintenanceService(apiClient);

export const MaintenanceWindowSetting = ({entityID}) => {
    const { timeZone } = useCurrentFacilityTimezone();
    const classes = useStyles();
    const [maintenanceWindow, setMaintenanceWindow] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState(true);
    const toolTipMsg = "When a device update is available, the device will wait until this time to implement the update. Set this to a time of day that is least busy.";

    useEffect(() => {
        const fetchMaintenanceWindows = async () => {
        try {
            if (!entityID) return;
            const result = await service.getMaintenanceWindows(entityID);
            if(result?.data){
                setToggle(result.data.isEnabled);
            }
            setMaintenanceWindow(result?.data ? result.data : null);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
        };
  
    fetchMaintenanceWindows();
  }, [entityID]);

  const handleSubmitForm = async (day, duration, startTime) => {
        try {
            await service.rescheduleMaintenanceWindow(entityID, day,startTime, duration, timeZone);
            setMaintenanceWindow({...maintenanceWindow, startDay: day, timeSpan: startTime, duration: duration});
        } catch (error) {
            console.log(error);
        }        
    }

    const onToggle = () => {
        if(maintenanceWindow !== null){
            const {startDay,timeSpan,duration} = maintenanceWindow;
            service.updateMaintenanceStatus(entityID, startDay, timeSpan, duration, timeZone, !toggle);
        }
        setToggle(!toggle);
    }

    return (
        <div className={classes["update-window-form-container"]}>
            {loading && <Loading />}
            {!loading &&  
                <>
                    <div className={classes["update-window-form-header"]}>
                        <Typography component="h6">Device Maintenance Window</Typography> 
                        <Tooltip title={toolTipMsg}>
                            <HelpIcon color='action' fontSize='small'/>
                        </Tooltip>
                        <Switch
                            name="lostticketallowvalidations"
                            checked={toggle}
                            data-checked={toggle}
                            onChange={onToggle}
                            color="primary"
                        />
                    </div>
                    {toggle &&
                        <MaintenanceWindowForm 
                            handleSubmit={handleSubmitForm} 
                            maintenanceWindow={maintenanceWindow}
                            timeZone={timeZone}
                            key={maintenanceWindow}
                        />
                    }
                   </> 
            }
        </div>
    )
}
