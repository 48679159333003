import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  ticketlessFeeTitle: {
    margin: theme.spacing(1),
    marginBottom: 0
  },
  drawerContainer: {
    padding: theme.spacing(1),
  },
  drawerItems: {
    padding: theme.spacing(1),
  },

  btnControl: {
    margin: theme.spacing(1),
    float: "right"
  },
  selectError: {
    color: "#e70022",
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
  },
  flexInput: {
    flexGrow: "1"
  },
  footerContainer: {
  },
  ticketlessFeeDrawer: {
    width: "100%",
    minWidth: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "22vw",
      minWidth: 400,
      flexShrink: 0,
    },
  },
  headerDesc: {
    marginLeft: 25,
    marginBottom: 5,
    marginTop: 15
  },
  resetButton: {
    display: "inline-flex",
    width: "100%"
  },
  ticketlessLPRBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },
}));
