import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	Box,
	Grid,
	Button,
	Divider,
	Typography,
	FormControl,
	MenuItem,
	TextField,
	Tooltip
} from "@material-ui/core";
import Title from "../../../Title";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import * as Yup from "yup";
import { useStyles } from "./styles";
import apiClient from "../../../../auth/apiClient";
import DeviceService from "../../../../services/DeviceService";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { useSelector } from "react-redux";

export const reverseLaneSchema = Yup.object().shape({
	laneName: Yup.string().required("Required"),
	entryDevice: Yup.string().required("Required"),
	exitDevice: Yup.string().required("Required"),
	reversingTimeOut: Yup.number().required("Required")
});

const deviceService = new DeviceService(apiClient);

const ReverseLaneForm = ({
	handleSave,
	handleEdit,
	handleCancel,
	reverseLane
}) => {

	const classes = useStyles();
	const {
		setValue,
		handleSubmit,
		watch,
		control,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: { ...reverseLane },
		resolver: yupResolver(reverseLaneSchema),
		shouldUnregister: false,
	});

	const onSubmit = async (data) => {
		if (reverseLane.laneID === undefined) {
			await handleSave(data);
		} else {
			await handleEdit(data);
		}
	};

	const { facilityID } = useCurrentFacility();
	const [entryDevices, setEntryDevices] = useState([]);
	const [exitDevices, setExitDevices] = useState([]);
	const enqueueSnackbar = useEnqueueSnackbar();

	useEffect(() => {
		if (facilityID && facilityID != null) {
			getExitDevices();
			getEntryDevices();
		}
	}, [facilityID]);

	const getEntryDevices = async () => {
		try {
			var result = await deviceService.getEntitiesByDeviceMode(facilityID, null, null, "", "Entry");
			setEntryDevices(result.data.collection ?? []);
		} catch (err) {
			enqueueSnackbar("Unable to retrieve entry devices for this entity", {
				variant: "error",
				tag: "FailedToFindEntryDevicesForEntity",
			});
		}
	};

	const getExitDevices = async () => {
		try {
			var result = await deviceService.getEntitiesByDeviceMode(facilityID, null, null, "", "Exit");
			setExitDevices(result.data.collection ?? []);
		} catch (err) {
			enqueueSnackbar("Unable to retrieve exit devices for this entity", {
				variant: "error",
				tag: "FailedToFindExitDevicesForEntity",
			});
		}
	};

	return (
		<div className={classes.reverseLaneDrawer} data-reverselane-id={reverseLane.laneID === undefined ? "new" : reverseLane.laneID}>
			<Box className={classes.reverseLaneBox}>
				<Grid className={clsx([classes.headerContainer])}>
					<Grid container className={clsx([classes.headerContainerSpacing])}>
						<Grid item xs={8} lg={10}>
							<Typography className={clsx([classes.title])}>
								<Title>{reverseLane.laneID === undefined ? "Add Reversing Lanes" : "Edit Reversing Lanes"}</Title>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Divider className={classes.reverseLaneDividerBottom} />
				<Grid className={clsx([classes.mainContainer])}>
					<Typography className={clsx([classes.ConfigurationTitle, "title"])}>
						Lane Name
					</Typography>
					<Grid container>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="laneName"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="LaneName" role="lane-name">
										<TextField
											{...field}
											id="laneName"
											className={clsx("laneName", classes.flexInput)}
											label="Lane Name"
											variant="outlined"
											error={!!errors.laneName}
											helperText={errors.laneName && errors.laneName.message}
										/>
									</FormControl>
								)}
							/>
						</Grid>
					</Grid>
					<Typography className={clsx([classes.ConfigurationTitle, "title"])}>
						Configuration
					</Typography>
					<Grid container>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="entryDevice"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="Entrance" role="entry-device">
										<TextField
											id="entryDevice"
											label="Entrance"
											select
											{...field}
											data-value={field?.value}
											className={clsx("entryDevice", classes.flexInput)}
											variant="outlined"
											onChange={(e) => setValue('entryDevice', e.target.value, { shouldValidate: true })}
											error={!!errors.entryDevice}
											helperText={errors.entryDevice && errors.entryDevice.message}
											SelectProps={{
												SelectDisplayProps: { "data-testid": "entrydevice" },
											  }}
										>
											{entryDevices.map((row) => {
												return (
													<MenuItem
														key={row.deviceID}
														id={row.deviceID}
														value={row.deviceID}
														name="entryDeviceList"
														data-name={row.deviceID}
													>
														{row.name}
													</MenuItem>
												);
											})}
										</TextField>
									</FormControl>
								)}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="exitDevice"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="Exit" role="exit-device">
										<TextField
											id="exitDevice"
											label="Exit"
											select
											{...field}
											data-value={field?.value}
											className={clsx("exitDevice", classes.flexInput)}
											variant="outlined"
											error={!!errors.exitDevice}
											helperText={errors.exitDevice && errors.exitDevice.message}
										>
											{exitDevices.map((row) => {
												return (
													<MenuItem
														key={row.deviceID}
														id={row.deviceID}
														value={row.deviceID}
														name="exitDeviceList"
														data-name={row.deviceID}
													>
														{row.name}
													</MenuItem>
												);
											})}
										</TextField>
									</FormControl>
								)}
							/>
						</Grid>
					</Grid>
					<Tooltip title="The length (in minutes) the system will wait for devices to complete the reversing process before abandoning the task.">
						<Grid container>
							<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>

								<Controller
									name="reversingTimeOut"
									control={control}
									render={({ field }) => (
										<FormControl fullWidth FormLabel="Reversing Time-Out Window" role="reversing-timeout">
											<TextField
												{...field}
												id="reversingTimeOut"
												data-value={field?.value}
												className={clsx("timeout", classes.flexInput)}
												label="Reversing Time-Out Window"
												variant="outlined"
												error={!!errors.reversingTimeOut}
												helperText={errors.reversingTimeOut && errors.reversingTimeOut.message}
											/>
										</FormControl>
									)}
								/>
							</Grid>
						</Grid>
					</Tooltip>


				</Grid>
			</Box>
			<Box className={clsx([classes.drawerDetailConfiguration])}>
				<div>
					<Divider className={classes.reverseLaneDividerFixed} />
				</div>
				<Button
					className={clsx("cancelBtn", classes.btnControl)}
					name="cancel"
					variant="contained"
					onClick={handleCancel}
					disabled={isSubmitting}
					data-testid="cancelBtn"
				>
					Close
				</Button>
				<Button
					color="primary"
					name="submit"
					type="submit"
					variant="contained"
					className={clsx("saveBtn", classes.btnControl)}
					onClick={handleSubmit(onSubmit)}
					disabled={isSubmitting}
					data-testid="saveBtn"
				>
					Save
				</Button>
			</Box>
		</div>
	);
};

ReverseLaneForm.defaultProps = {
	handleSave: () => { },
	handleEdit: () => { },
	handleCancel: () => { },
	reverseLane: {}
};

ReverseLaneForm.propTypes = {
	handleSave: PropTypes.func,
	handleEdit: PropTypes.func,
	handleCancel: PropTypes.func,
};

export default ReverseLaneForm;