import React, { useState, useEffect, useReducer } from "react";

/* MUI imports */
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  Drawer
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* styles */
import clsx from "clsx";
import { useStyles } from "./styles";
import { useStyles as useRootStyles } from "../styles";

/* Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays as calendarIcon } from "@fortawesome/pro-duotone-svg-icons";

/* form */
import { useSettingDispatchChange } from "../index";
import ChipMultiSelect from "../../../Dropdowns/ChipMultiSelect";
import PayOnEntrySortMenuButton from "../PayOnEntrySortMenuButton";
import SettingsResetButton from "../SettingsResetButton";
import useFormHandlers from "../useFormHandlers";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

/* State */
import usePoeState from "../../../../state/slices/POE/usePoeState";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { initalPayOnEntryState } from "../../../../reducers/payonentry/payonentryofferReducer";
import poeOfferReducer from "../../../../reducers/payonentry/payonentryofferReducer";
import PayOnEntryComposer from "../../../../components/PayOnEntry/PayOnEntryComposer";

/* Hooks */
import useHasPermissions from "../../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";

/* Utilities */
import * as _ from "lodash";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlags";
import { getOfferObjectInCorrectSettingOrder } from "../../../../utils/sorting/poe";
import ICalParser from 'ical-js-parser';
import { RRule, rrulestr } from 'rrule';
import moment from 'moment';
import apiClient from "../../../../auth/apiClient";
import PayOnEntryOfferScheduleService from '../../../../services/PayOnEntryOfferScheduleService';

/* Full Calendar */
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export const payOnEntrySchema = Yup.object().shape({
  ["poe.enabled"]: Yup.bool(),
  ["poe.attendedmode"]: Yup.bool(),
  ["poe.showticketbutton"]: Yup.bool(),
});

export const handleOfferEquality = (offersA, offersB) => {
  let offersAActual = offersA;
  let offersBActual = offersB;
  // the actual offer array is persisted as a serialized value; attempt deserialization
  if (typeof offersA === "string") offersAActual = JSON.parse(offersA);
  if (typeof offersB === "string") offersBActual = JSON.parse(offersB);
  if (offersAActual == null || offersBActual == null) return false;
  if (!Array.isArray(offersAActual) || !Array.isArray(offersBActual))
    // invalid object type; return true so we don't allow the user to hit the refresh wheel
    return true;
  if (offersAActual.length !== offersBActual.length) return false;
  if (!_.isEqual(offersAActual, offersBActual)) return false;
  return true;
};

const sortOffers = (offers) => {
  return offers.sort(sortOffersAscending);
};
const sortOffersAscending = (offerA, offerB) => {
  const nameA = offerA?.value?.toLowerCase() ?? "",
    nameB = offerB?.value?.toLowerCase() ?? "";
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const PayOnEntrySettings = ({
  identifier,
  entityID,
  entityType,
  settingValues,
  parentSettingValues,
}) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    open: false
  });
  const rootClasses = useRootStyles();
  const cashieredDevices = useFeatureFlag("Cashiered Device");
  const [expanded, setExpanded] = useState(true);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { dispatchSetting } = useSettingDispatchChange();
  const { offers } = usePoeState();
  const { control, setValue, watch } = useForm({
    resolver: yupResolver(payOnEntrySchema),
    defaultValues: {
      poe: {
        enabled: settingValues?.poeEnabled ?? false,
        attendedmode: settingValues?.poeAttendedMode ?? false,
        showticketbutton: settingValues?.poeShowTicketButton ?? false,
      },
      poeoffers:
        typeof settingValues?.poeOffers === "string"
          ? JSON.parse(settingValues?.poeOffers)
          : settingValues?.poeOffers ?? [],
    },
  });
  const [dateRange, setDateRange] = useState();
  const [calendarEvents, setCalendarEvents] = useState();
  const { hasPermissions } = useHasPermissions();
  const EditPayOnEntryPermission = hasPermissions(["payonentry.edit"]);
  const {timeZone} = useCurrentFacilityTimezone();
  const [state, dispatch] = useReducer(
    poeOfferReducer,
    initalPayOnEntryState
  );
  const handleEdit = offerID => {
    dispatch({
      type: "EDIT_POE_OFFER",
      payload: {
        offerID
      }
    });
  };

  const handleCancel = () => {
    getPOEOfferScheduleCalendarEvents(dateRange);
    dispatch({
      type: "CLOSE_FORM"
    });
  };

  const handleSaved = () => {
    getPOEOfferScheduleCalendarEvents(dateRange);
    dispatch({
      type: "CLOSE_FORM"
    });
  };

  const watchFields = watch();
  const { handleResetChange } = useFormHandlers(
    setValue,
    settingValues,
    entityID,
    entityType
  );

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleOffersCalendar = () => {
    setFormState({ open: true });
  };

  const handlePayOnEntryChange = async (e) => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.poeEnabled) return;
    if (!(await dispatchSetting(entityID, "poe.enabled", targetValue)))
      enqueueSnackbar("Unable to set Pay on Entry mode", {
        variant: "error",
        tag: "PoeEnabled",
      });
  };

  const handleAttendedModeChange = async (e) => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.poeAttendedMode) return;
    if (!(await dispatchSetting(entityID, "poe.attendedmode", targetValue)))
      enqueueSnackbar("Unable to set Attended Cashier Mode", {
        variant: "error",
        tag: "PoeAttendedCachierMode",
      });
  };

  const handleShowTicketBtnChanged = async (e) => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.poeShowTicketButton) return;
    if (!(await dispatchSetting(entityID, "poe.showticketbutton", targetValue)))
      enqueueSnackbar("Unable to set Show Ticket Button for Pay on Entry", {
        variant: "error",
        tag: "PoeShowTicketButton",
      });
  };

  const formatOffers = (offers) => {
    if (!offers) return [];
    return offers.map((offer) => ({
      id: offer.offerID,
      value: offer.offerName,
    }));
  };

  const handlePOEOfferChange = async (offers, prevOffers) => {
    const ids = offers?.map((offer) => offer.id) ?? [];
    let setOffers = false;
    if (prevOffers) {
      //state of the sort is being handled in this parent comp
      //we want to set state of from for sort right away, and revert only if settings update fails
      setValue("poeoffers", ids);
      setOffers = true;
    }

    if (!(await dispatchSetting(entityID, "poeoffers", JSON.stringify(ids)))) {
      enqueueSnackbar("Unable to set Pay on Entry offers", {
        variant: "error",
        tag: "PoeOffers",
      });
      if (prevOffers) {
        const prevOfferIDs = prevOffers?.map((offer) => offer.id) ?? [];
        setValue("poeoffers", prevOfferIDs);
      }
    } else if (!setOffers) {
      setValue("poeoffers", ids);
    }
  };

  const getOffersById = (offerIDs) => {
    if (!offerIDs || _.isEmpty(offerIDs)) return [];
    try {
      if (typeof offerIDs === "string") offerIDs = JSON.parse(offerIDs);
      return getOfferObjectInCorrectSettingOrder(offers, offerIDs);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const handleDateChange = (dateRange) => {
    setDateRange(dateRange);
    getPOEOfferScheduleCalendarEvents(dateRange);
  };

  const poeOfferScheduleService = new PayOnEntryOfferScheduleService(apiClient);
  const getPOEOfferScheduleCalendarEvents = async dateRange => {
    try {
      const response = await poeOfferScheduleService.getPOEOfferScheduleByEntityId(entityID, dateRange?.startStr, dateRange?.endStr);
      const offers = response.data.length > 0 ? response.data : [];
      let events = []
      if (offers.length > 0) {
        offers.forEach((offer) => {
          const icalJson = ICalParser.toJSON(offer.offerSchedules);
          icalJson.events.forEach((icalEvent) => {
            if (icalEvent.rrule) {
              const icalEventStart = moment(icalEvent.dtstart.value);
              const icalEventEnd = moment(icalEvent.dtend.value);
              const difference = icalEventEnd.diff(icalEventStart, 'milliseconds')
              const dtstartInFacilityTz = moment.tz(icalEvent.dtstart.value, timeZone).format('YYYYMMDDTHHmmss');
              const rrule = rrulestr(`DTSTART;TZID=${timeZone}:${dtstartInFacilityTz}\nRRULE:${icalEvent.rrule}`)
              const rule = new RRule(rrule.origOptions)
              const recurEventStarts = rule.between(new Date(dateRange?.startStr), new Date(dateRange?.endStr))
              const recurEvents = recurEventStarts.map((recurEventStart) => {
                const eventStart = moment(recurEventStart);
                const eventEnd = moment(recurEventStart).add(difference, 'milliseconds')
                return {
                  title: offer.offerName,
                  start: moment.utc(eventStart).format('YYYYMMDDTHHmmss'),
                  end: moment.utc(eventEnd).format('YYYYMMDDTHHmmss'),
                  extendedProps: {
                    offerId: offer.offerID
                  }
                }
              })
              events.push(...recurEvents)
            } else {
              const event = {
                title: offer.offerName,
                start: moment(icalEvent.dtstart.value).toISOString(),
                end: moment(icalEvent.dtend.value).toISOString(),
                extendedProps: {
                  offerId: offer.offerID
                }
              }
              events.push(event);
            }
          })
        })
      }
      setCalendarEvents(events);
    } catch (err) {
      console.log(err)
      enqueueSnackbar("Failed to get POE Calendar Events.", {
        variant: "error",
        tag: "FailedToGetPOEOfferScheduleCalendarEvents"
      });
    }
  }

  return (
    <Grid
      data-testid={identifier}
      className={clsx("poe-panel", rootClasses.panelRoot)}
      container
    >
      <Accordion
        className={clsx("accordion", rootClasses.fullWidthAccordion)}
        expanded={expanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleAccordion}
        >
          <Typography className={clsx("heading", rootClasses.heading)}>
            Pay on Entry
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="poe.enabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    role="pay-on-entry-toggle"
                    label="Enable Pay on Entry"
                    control={
                      <Switch
                        {...field}
                        id="poeEnabled"
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          handlePayOnEntryChange(e);
                        }}
                        checked={field.value}
                        data-checked={field.value}
                      />
                    }
                  />
                )}
              />
              <SettingsResetButton
                entityID={entityID}
                entityType={entityType}
                settingName="poe.enabled"
                currentValue={watchFields.poe.enabled}
                originalValue={parentSettingValues.poeEnabled}
                onClick={() => {
                  handleResetChange(
                    "poe.enabled",
                    parentSettingValues.poeEnabled
                  );
                }}
              />
              {(watchFields.poe.enabled === true) && (
                <Button
                  data-testid="selectOffer"
                  className={clsx(classes.calendarIconButtonText)}
                  onClick={handleOffersCalendar}
                >
                  <FontAwesomeIcon
                    className={classes.calendarIconButtonText}
                    icon={calendarIcon}
                  ></FontAwesomeIcon>
                </Button>
              )}
              <Dialog
                fullWidth
                maxWidth="md"
                open={formState.open}
                data-testid="poeOfferCalendar"
              >
                <DialogContent>
                  <Fullcalendar
                    customButtons={{
                      cancelButton: {
                        text: "close",
                        click: function () {
                          setFormState({ open: false });
                        },
                      }
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                      start: "today,prev,next",
                      center: "title",
                      end: "cancelButton",
                    }}
                    buttonIcons={{
                      prev: 'chevron-left',
                      next: 'chevron-right'
                    }}
                    height={"90vh"}
                    datesSet={handleDateChange}
                    events={calendarEvents}
                    eventClick={(info) => {
                      handleEdit(info.event._def.extendedProps.offerId);
                    }
                    }
                  />
                </DialogContent>
              </Dialog>
              {(EditPayOnEntryPermission) && (
                <Drawer
                  className={clsx("pay-on-entry", "drawer")}
                  anchor="right"
                  open={state.showForm}
                  classes={{ paper: classes.drawerPaper, root: classes.drawer }}
                >
                  <PayOnEntryComposer
                    entityID={entityID}
                    offerId={state.offerID}
                    handleSave={handleSaved}
                    handleCancel={handleCancel}
                  />
                </Drawer>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {cashieredDevices && watchFields.poe.enabled === true && (
                <>
                  <Controller
                    name="poe.attendedmode"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        role="attended-cashier-mode-toggle"
                        label="Attended Cashier Mode"
                        control={
                          <Switch
                            {...field}
                            id="poeAttendedMode"
                            color="primary"
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              handleAttendedModeChange(e);
                            }}
                            checked={field.value}
                            data-checked={field.value}
                          />
                        }
                      />
                    )}
                  />
                  <SettingsResetButton
                    className={classes.resetPoeWheel}
                    entityID={entityID}
                    entityType={entityType}
                    settingName="poe.attendedmode"
                    currentValue={watchFields.poe.attendedmode}
                    originalValue={parentSettingValues.poeAttendedMode}
                    onClick={() => {
                      handleResetChange(
                        "poe.attendedmode",
                        parentSettingValues.poeAttendedMode
                      );
                    }}
                  />
                </>
              )}
            </Grid>
            {1 == 2 && ( // TODO: remove soft flag during phase 2 of POE
              <Grid item xs={12}>
                <Controller
                  name="poe.showticketbutton"
                  control={control}
                  render={({ field }) => (
                    <Tooltip title="When this setting is enabled, patrons can press the Ticket button to issue an unpaid ticket that can be paid at a central pay or exit device.">
                      <FormControlLabel
                        role="ticket-button-toggle"
                        label="Show Ticket button on payment screen"
                        control={
                          <Switch
                            {...field}
                            id="poeShowTicketButton"
                            color="primary"
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              handleShowTicketBtnChanged(e);
                            }}
                            checked={field.value}
                            data-checked={field.value}
                          />
                        }
                      />
                    </Tooltip>
                  )}
                />
                <SettingsResetButton
                  className={classes.resetPoeWheel}
                  entityID={entityID}
                  entityType={entityType}
                  settingName="poe.showticketbutton"
                  currentValue={watchFields.poe.showticketbutton}
                  originalValue={parentSettingValues.poeShowTicketButton}
                  onClick={() => {
                    handleResetChange(
                      "poe.showticketbutton",
                      parentSettingValues.poeShowTicketButton
                    );
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} className={classes.offerContainer}>
              <Controller
                name="poeoffers"
                control={control}
                render={({ field }) => (
                  <>
                    <Grid item xs={10} md={11}>
                      <ChipMultiSelect
                        className={classes.poeSelector}
                        identifier="poe-selector"
                        label="Pay on Entry Offers"
                        options={sortOffers(formatOffers(offers))}
                        onChange={handlePOEOfferChange}
                        selected={formatOffers(getOffersById(field.value))}
                      />
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <PayOnEntrySortMenuButton
                        items={formatOffers(getOffersById(field.value))}
                        onSort={handlePOEOfferChange}
                      />
                    </Grid>
                  </>
                )}
              />
              <SettingsResetButton
                className={classes.resetPoeWheel}
                entityID={entityID}
                entityType={entityType}
                settingName="poeoffers"
                currentValue={watchFields.poeoffers}
                originalValue={parentSettingValues.poeOffers}
                onClick={() => {
                  handleResetChange("poeoffers", parentSettingValues.poeOffers);
                }}
                equality={handleOfferEquality}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default PayOnEntrySettings;
