import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  panelRoot: {
    marginBottom: theme.spacing(2),
    "@media print":
      {
        display: 'none'
      },
  },

  heading: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.primary.main,
  },
  shortChangeInstructions: {
    width: "100%",
  },
  shortChangeTransactions: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  fullWidthAccordion: {
    width: "100%",
  },
}));
