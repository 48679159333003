import MomentUtils from "@date-io/moment";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { debounce, isUndefined } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlags";
import * as Yup from "yup";
import apiClient from "../../../../auth/apiClient";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import AccessGroupService from "../../../../services/AccessGroupService";
import HotelInterfaceService from "../../../../services/HotelInterfaceService";
import useFormHandlers from "../useFormHandlers";
import { useSettingDispatchChange } from "../../Settings/index";
import { useSelector } from "react-redux";

/* Style */
import clsx from "clsx";
const accessGroupService = new AccessGroupService(apiClient);
const hotelInterfaceService = new HotelInterfaceService(apiClient);

export const HotelInterfaceSchema = Yup.object().shape({
  hoteltype: Yup.string().required("Required"),
  connectiontype: Yup.string().required("Required"),
  accessgroupid: Yup.string().when("hoteltype", {
    is: (value) => value !== "Opera",
    then: Yup.string().required("Required"),
    otherwise: Yup.string()
      .nullable()
      .notRequired(),
  }),
  defaultiostatus: Yup.string().required("Required"),
  deactivationtime: Yup.date().required("Required"),

  postchargestime: Yup.date().when("hoteltype", {
    is: (value) => value === "Opera",
    then: Yup.date().required("Required"),
    otherwise: Yup.date()
      .nullable()
      .notRequired(),
  }),

  daysuntilcardpurge: Yup.number()
    .typeError("Must be a number")
    .integer("Must be a whole number")
    .positive("Must be a positive value")
    .moreThan(-1, "Must be between 0 and 365 days")
    .lessThan(366, "Must be between 0 and 365 days")
    .required("Required"),
  serialport: Yup.string().when("connectiontype", {
    is: "Serial",
    then: Yup.string().required("Required"),
    otherwise: Yup.string()
      .nullable()
      .notRequired(),
  }),
  baudrate: Yup.number().when("connectiontype", {
    is: "Serial",
    then: Yup.number().required("Required"),
    otherwise: Yup.number()
      .nullable()
      .notRequired(),
  }),
  parity: Yup.string().when("connectiontype", {
    is: "Serial",
    then: Yup.string().required("Required"),
    otherwise: Yup.string()
      .nullable()
      .notRequired(),
  }),
  databits: Yup.number().when("connectiontype", {
    is: "Serial",
    then: Yup.number().required("Required"),
    otherwise: Yup.number()
      .nullable()
      .notRequired(),
  }),
  stopbits: Yup.number().when("connectiontype", {
    is: "Serial",
    then: Yup.number().required("Required"),
    otherwise: Yup.number()
      .nullable()
      .notRequired(),
  }),
  ipaddress: Yup.string().when("connectiontype", {
    is: "TCP",
    then: Yup.string()
      .matches(
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        "Must be valid IP address"
      )
      .required("Required"),
    otherwise: Yup.string()
      .nullable()
      .notRequired(),
  }),
  port: Yup.number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable()
    .when("connectiontype", {
      is: "TCP",
      then: Yup.number()
        .typeError("Must be a number")
        .integer("Must be a whole number")
        .positive("Must be positive value")
        .moreThan(0, "Must be greater than 0")
        .required("Required"),
    }),
  ["postchargesenabled"]: Yup.bool(),
  otherwise: Yup.number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable()
    .notRequired(),
});
const createDateFrom24HourTimeString = (timeString) => {
  const timeArray = timeString.split(":");
  return new Date(new Date().setHours(timeArray[0], timeArray[1]));
};

const HotelInterfaceForm = ({ entityID, entityType, settingValues, onHotelRatesClicked }) => {
  const enqueue = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const usingOperaMicrosFidelio = useFeatureFlag('Hotel Integrations.Opera Micros/Fidelio');
  
  const getFromInitalValues = () => {
    const defaultValues = {
      hoteltype: "",
      connectiontype: "",
      accessgroupid: "",
      defaultiostatus: "neutral",
      daysuntilcardpurge: 60,
      deactivationtime: new Date(new Date().setHours(0, 0, 0, 0)),
      postchargestime: new Date(new Date().setHours(3, 0, 0, 0)),
      serialport: "/dev/ttyUSB0",
      baudrate: 9600,
      parity: "odd",
      databits: 7,
      stopbits: 2,
      ipaddress: "",
      port: "",
      postchargesenabled: false,
    };
    const hotelinterfaceValues = JSON.parse(settingValues.hotelinterface);
    //Merge difference in expected props and props in database
    const initialValues =  { ...defaultValues, ...hotelinterfaceValues };
    if(typeof initialValues.deactivationtime ==="string") {
      initialValues.deactivationtime = createDateFrom24HourTimeString(initialValues.deactivationtime);
    }
    if(typeof initialValues.postchargestime ==="string") {
      initialValues.postchargestime = createDateFrom24HourTimeString(initialValues.postchargestime);
    }
    return initialValues;
  }

  const [
    hotelInterfaceDatabaseValuesRHF,
    setHotelInterfaceValuesRHF,
  ] = useState(getFromInitalValues);
  const [isPredeterminedConnection, setIsPredeterminedConnection] = useState(
    false
  );
  const [accessGroups, setAccessGroups] = useState([]);
  const tcpConnection = "TCP";
  const serialConnection = "Serial";
  const hotelTypes = {
    MarriottFosse: {
      name: "Marriott/Fosse",
      connectionType: serialConnection,
    },
    Galaxy: {
      name: "Galaxy",
      connectionType: serialConnection,
    },
    HiltonOnQ: {
      name: "Hilton OnQ",
      connectionType: tcpConnection,
    },
    Opera: {
      name: "Opera Micros/Fidelio",
      connectionType: tcpConnection,
    },
  };
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    formState,
    trigger,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(HotelInterfaceSchema),
    defaultValues: hotelInterfaceDatabaseValuesRHF,
    mode: "onChange",
  });
  const watchFields = watch();
  const watchedData = useWatch({
    control,
    defaultValue: hotelInterfaceDatabaseValuesRHF,
  });
  const onSubmit = (data,rates) => {
    const hotelType = data["hoteltype"];
    if (hotelType == "Opera"){
      //remove accessgroupid for hotel type opera
      if (data.hasOwnProperty("accessgroupid")){
        delete data.accessgroupid;
      }
      data.rates = [...rates];
    }
    data["deactivationtime"] =
      data["deactivationtime"].getHours() +
      ":" +
      data["deactivationtime"].getMinutes(); //Convert Date object to just 24 hour string

    data["postchargestime"] =
      data["postchargestime"].getHours() +
      ":" +
      data["postchargestime"].getMinutes(); //Convert Date object to just 24 hour string

    const event = {
      target: {
        name: "hotelinterface",
        value: JSON.stringify(data),
      },
    };
    handleInputChange(errors, event);
  };

  const { dispatchSetting } = useSettingDispatchChange();
  const saveOperaEntityId = useCallback((hotelType) => {
    if (hotelType === "Opera") {
      dispatchSetting(facilityID, "operaentityid", entityID);
    } else {
      dispatchSetting(facilityID, "operaentityid", "");
    }
  }, [facilityID,entityID],
  );

  const debouncedSave = useCallback(
    debounce((rates) => {
      handleSubmit(onSubmit)(rates);
    }, 1000),
    []
  );

  useEffect(() => {
  const initialValues = getFromInitalValues();
    reset({...initialValues})
    setHotelInterfaceValuesRHF({...initialValues});

  }, [settingValues.hotelinterface]);

  useEffect(() => {
    if (!formState.isValidating && formState.isDirty && formState.isValid) {
      const hotelinterfaceValues = JSON.parse(settingValues.hotelinterface);
      const rates = hotelinterfaceValues.rates === undefined ? [] : hotelinterfaceValues.rates;
      debouncedSave(rates);
    }
  }, [formState.isValidating]);
  useEffect(() => {
    const hotelType = watchedData["hoteltype"];
    if (hotelType !== "") {
      saveOperaEntityId(hotelType);
      const hotelTypeListValue = hotelTypes[hotelType];
      if (hotelType == "HiltonOnQ") {
        setValue("port", 38117);
      }
      if (hotelTypeListValue.connectionType == serialConnection) {
        setIsPredeterminedConnection(true);
        setValue("connectiontype", serialConnection);
      } else if (hotelTypeListValue.connectionType == tcpConnection) {
        setIsPredeterminedConnection(true);
        setValue("connectiontype", tcpConnection);
      } else {
        setIsPredeterminedConnection(false);
      }
      trigger();
    }
  }, [watchedData["hoteltype"]]);

  const fetchAccessGroups = useCallback(
    async (scopeAwareFacilityID) => {
      let response;
      try {
        response = await accessGroupService.GetAccessGroups(scopeAwareFacilityID);
      } catch {
        enqueue("Failed to retrieve Access Groups", {
          variant: "error",
          tag: "accessGroupFetchError",
        });
        return;
      }
      if (response.data.totalCount > 0) {
        const filteredAccessGroups = response.data.collection.filter(
          (accessGroup) => accessGroup.type == "Normal"
        );
        setAccessGroups(filteredAccessGroups);
      }
    },
    [scopeAwareFacilityID]
  );
  useEffect(() => {
    if (!isUndefined(scopeAwareFacilityID)) fetchAccessGroups(scopeAwareFacilityID);
  }, [scopeAwareFacilityID]);

  const { setFieldValue, initialValues } = useFormik({
    initialValues: hotelInterfaceDatabaseValuesRHF,
  });
  const { handleInputChange } = useFormHandlers(
    setFieldValue,
    initialValues,
    entityID,
    entityType
  );

  const handlePostChargesChange = async (e) => {
    const targetValue = e.target.checked;
    if (targetValue === settingValues.postchargesenabled) return;
  };

  const handleHotelRatesButton = async (e) => {
    trigger();
    if (formState.isValid) {
      onHotelRatesClicked(e);
    } else {
      enqueue("Please fill the required fields before entering Hotel Rates", {
        variant: "error",
        tag: "FormValidationError",
      });
      return;
    }
  };

  const featureFlagFilter = useCallback(
    hotelType => {
      return !usingOperaMicrosFidelio ? hotelType[0] != "Opera" : true;
    },
    [usingOperaMicrosFidelio]
  );

  const handleDataResyncButton = async () => {
    try {
      var result = await hotelInterfaceService.RequestDataReSync(entityID);
      if (result.status === 200) {
        enqueue("Data Resync requested successfully", {
          variant: "success",
          tag: "updateDataResyncSuccess",
        });
      } else {
        enqueue("Failed to update request Data Resync", {
          variant: "error",
          tag: "updateDataResyncError",
        });
      }
    } catch {
      enqueue("Failed to update request Data Resync", {
        variant: "error",
        tag: "updateDataResyncError",
      });
      return false;
    }
  };

  return (
    <>
      <Grid item data-tag="spacer" xs={12} />
      <Grid item xs={12} sm={4}>
        <Controller
          name="hoteltype"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="hoteltype"
              label="Hotel Type"
              select
              value={field.value ?? ""}
              style={{ width: "100%" }}
              SelectProps={{
                SelectDisplayProps: { "data-testid": "hoteltype" },
              }}
            >
              {Object.entries(hotelTypes)
                .filter((hotelType) => featureFlagFilter(hotelType))
                .map((hotelType) => (
                  <MenuItem key={hotelType[0]} value={hotelType[0]}>
                    {hotelType[1].name}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </Grid>

      {getValues("hoteltype") === "Opera" && (
        <>
          <Grid item data-tag="spacer" xs={12} />
          <Grid item xs={12} sm={4}>
            <Button
              className={clsx(["btn-rates"])}
              fullWidth
              variant="contained"
              onClick={handleHotelRatesButton}
              color="primary"
            >
              Hotel Rates
            </Button>
          </Grid>
        </>
      )}
      {getValues("hoteltype") !== "" && (
        <>
          <Grid item data-tag="spacer" xs={12} sm={3} />
          <Grid item xs={12} sm={4}>
            <InputLabel id="demo-simple-select-outlined-label">
              Connection Type
            </InputLabel>
            <Controller
              name="connectiontype"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="connectiontype"
                  select
                  onChange={(e) => {
                    field.onChange(e);
                    trigger();
                  }}
                  disabled={isPredeterminedConnection}
                  error={Boolean(errors.connectiontype)}
                  helperText={errors.connectiontype?.message}
                  style={{ width: "100%" }}
                  SelectProps={{
                    SelectDisplayProps: { "data-testid": "connectiontype" },
                  }}
                >
                  <MenuItem key="1" value={serialConnection}>
                    Serial
                  </MenuItem>
                  <MenuItem key="2" value={tcpConnection}>
                    TCP
                  </MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item data-tag="spacer" xs={12} />
          <Grid item xs={12} sm={4}>
            {getValues("hoteltype") !== "Opera" && (
              <Controller
                name="accessgroupid"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="accessgroupid"
                    label="Access Group"
                    select
                    error={Boolean(errors.accessgroupid)}
                    helperText={errors.accessgroupid?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    SelectProps={{
                      SelectDisplayProps: { "data-testid": "accessgroupid" },
                    }}
                  >
                    {accessGroups.map((accessGroup) => (
                      <MenuItem
                        key={accessGroup.accessGroupID}
                        value={accessGroup.accessGroupID}
                      >
                        {accessGroup.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}
            <Controller
              name="defaultiostatus"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="defaultiostatus"
                  label="Default I/O Status"
                  select
                  error={Boolean(errors.defaultiostatus)}
                  helperText={errors.defaultiostatus?.message}
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                  SelectProps={{
                    SelectDisplayProps: { "data-testid": "defaultiostatus" },
                  }}
                >
                  <MenuItem value="in">In</MenuItem>
                  <MenuItem value="out">Out</MenuItem>
                  <MenuItem value="neutral">Neutral</MenuItem>
                </TextField>
              )}
            />
            <Controller
              name="daysuntilcardpurge"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="daysuntilcardpurge"
                  label="Days after checkout until purge"
                  error={Boolean(errors.daysuntilcardpurge)}
                  helperText={errors.daysuntilcardpurge?.message}
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                  InputProps={{
                    "data-testid": "daysuntilcardpurge",
                  }}
                />
              )}
            />
            {getValues("hoteltype") === "Opera" && (
              <>
                <Controller
                  name="postchargesenabled"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      role="post-charges-toggle"
                      label="Post Charges Daily"
                      control={
                        <Switch
                          {...field}
                          id="postchargesswitch"
                          color="primary"
                          data-testid="postchargesswitch"
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            handlePostChargesChange(e);
                          }}
                          checked={field.value}
                          data-checked={field.value}
                        />
                      }
                    />
                  )}
                />

                <Controller
                  name="postchargestime"
                  control={control}
                  render={({ field }) => (
                    <Tooltip
                      title="Post Charges Time"
                      placement="top-end"
                    >
                      <span>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <TimePicker
                            disabled={!watchFields.postchargesenabled}
                            id="postchargestime"
                            label="Post Charges Time"
                            value={field.value}
                            onChange={field.onChange}
                            error={Boolean(errors.postchargestime)}
                            helperText={errors.postchargestime?.message}
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginBottom: "1rem",
                            }}
                            InputProps={{
                              "data-testid": "postchargestime",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </span>
                    </Tooltip>
                  )}
                />
              </>
            )}
            <Controller
              name="deactivationtime"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title="Card deactivation time on checkout date"
                  placement="top-end"
                >
                  <span>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <TimePicker
                        id="deactivationtime"
                        label="Deactivation Time"
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errors.deactivationtime)}
                        helperText={errors.deactivationtime?.message}
                        variant="outlined"
                        style={{
                          width: "100%",
                          marginBottom: "1rem",
                        }}
                        InputProps={{
                          "data-testid": "deactivationtime",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </span>
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item data-tag="spacer" xs={12} sm={3} />
          {getValues("connectiontype") === serialConnection && (
            <Grid item xs={12} sm={4}>
              <Controller
                name="serialport"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="serialport"
                    label="Serial Port"
                    error={Boolean(errors.serialport)}
                    helperText={errors.serialport?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    inputProps={{
                      "data-testid": "serialport",
                    }}
                  />
                )}
              />
              <Controller
                name="baudrate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="baudrate"
                    label="Baud Rate"
                    select
                    error={Boolean(errors.baudrate)}
                    helperText={errors.baudrate?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    SelectProps={{
                      SelectDisplayProps: { "data-testid": "baudrate" },
                    }}
                  >
                    <MenuItem value="1200">1200</MenuItem>
                    <MenuItem value="2400">2400</MenuItem>
                    <MenuItem value="4800">4800</MenuItem>
                    <MenuItem value="9600">9600</MenuItem>
                    <MenuItem value="14400">14400</MenuItem>
                    <MenuItem value="19200">19200</MenuItem>
                    <MenuItem value="38400">38400</MenuItem>
                    <MenuItem value="56000">56000</MenuItem>
                    <MenuItem value="57600">57600</MenuItem>
                  </TextField>
                )}
              />
              <Controller
                name="parity"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="parity"
                    label="Parity"
                    select
                    error={Boolean(errors.parity)}
                    helperText={errors.parity?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    SelectProps={{
                      SelectDisplayProps: { "data-testid": "parity" },
                    }}
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="even">Even</MenuItem>
                    <MenuItem value="odd">Odd</MenuItem>
                  </TextField>
                )}
              />
              <Controller
                name="databits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="databits"
                    label="Data Bits"
                    select
                    error={Boolean(errors.databits)}
                    helperText={errors.databits?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    SelectProps={{
                      SelectDisplayProps: { "data-testid": "databits" },
                    }}
                  >
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                  </TextField>
                )}
              />
              <Controller
                name="stopbits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="stopbits"
                    label="Stop Bits"
                    select
                    error={Boolean(errors.stopbits)}
                    helperText={errors.stopbits?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    SelectProps={{
                      SelectDisplayProps: { "data-testid": "stopbits" },
                    }}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          )}
          {getValues("connectiontype") === tcpConnection && (
            <Grid item xs={12} sm={4}>
              <Controller
                name="ipaddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="ipaddress"
                    label="IP Address"
                    error={Boolean(errors.ipaddress)}
                    helperText={errors.ipaddress?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    InputProps={{
                      "data-testid": "ipaddress",
                    }}
                  />
                )}
              />
              <Controller
                name="port"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="port"
                    label="Port"
                    error={Boolean(errors.port)}
                    helperText={errors.port?.message}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    InputProps={{
                      "data-testid": "port",
                    }}
                  />
                )}
              />

              {getValues("hoteltype") === "Opera" && (
                <Tooltip
                  title="This should only be used after the initial setup of an Opera interface. Please allow the resync time to complete the data transfer."
                >
                  <Button
                    className={clsx(["btn-dataresync"])}
                    fullWidth
                    variant="contained"
                    onClick={handleDataResyncButton}
                    color="primary"
                  >
                    Request Data Resync
                  </Button>
                </Tooltip>
              )}

            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default HotelInterfaceForm;
