import React, { useState, useEffect, useCallback } from "react";
import {
	Drawer,
	Button,
	IconButton,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	TextField
} from "@material-ui/core";


import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from "./PassThruMappingDrawer.style";
import clsx from "clsx";

import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useCancellationToken from "../../../../hooks/useCancellationToken";

import apiClient from "../../../../auth/apiClient";
import PassThruService from "../../../../services/PassThruService";


const passThruService = new PassThruService(apiClient);

const PassThruMappingDrawer = ({ passThruOpen, setPassThruOpen, facilityId}) => {
	const classes = useStyles();
	const [passThruEntries, setPassThruEntries] = useState([]);
	const [newEntryOpen, setNewEntryOpen] = useState(false);
	const enqueueSnackbar = useEnqueueSnackbar();

	const [newEntry, setNewEntry] = useState("");
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		if (facilityId) {
			executePassThru({ facilityId });
		}
		return () => cancelSearch()
	}, [facilityId]);

	const {
		execute: executePassThru,
		cancel: cancelSearch,
		inProgress,
	} = useCancellationToken({
		func: async function ({ facilityId, cancelToken }) {
			setPassThruEntries([]);
			var result = await passThruService.getPassThruEntries(
				facilityId,
				cancelToken
			);
			setPassThruEntries(result.data);
		},
		errHandleFunc : () => {
			enqueueSnackbar("Unable to load pass thru entries", {
				variant: "error",
				tag: "FailedToLoadPassThruEntries",
			});
		}
	})



	const handleEntryChange = (e) => {
		setNewEntry(e.target.value);
	}

	const handleEntrySave = (e) => {
		passThruService.createPassThruEntry(facilityId, newEntry).then(result => {
			setPassThruEntries([...passThruEntries, { passThruName: newEntry, passThruId: result.data }])
			setNewEntry("");
			setNewEntryOpen(false);
			setIsSaving(false);
		}).catch(err =>{
			enqueueSnackbar("Unable to save new pass thru entry", {
				variant: "error",
				tag: "FailedToSavePassThruEntry",
			});
		});
		setIsSaving(true);
	}

	const handleEntryCancel = (e) => {
		setNewEntry("");
		setNewEntryOpen(false);
	}

	const handleDelete = (id) => {
		passThruService.deletePassThruEntries(id).then(result => {
			setPassThruEntries(passThruEntries.filter(e => e.passThruId !== id))
		}).catch(err => {
			enqueueSnackbar("Unable to delete pass thru entry", {
				variant: "error",
				tag: "FailedToDeletePassThruEntry",
			});
		})
	}

	return (
		<Drawer anchor="right" open={passThruOpen} onClose={() => setPassThruOpen(false)} PaperProps={{ classes: { root: clsx('drawer', classes.drawer) } }}>
			<List>
				<ListItem key={"Header"}>
					<ListItemText
						className={classes.headerName}
						primary={"Pass-Thru Values"}
						primaryTypographyProps={{ variant: "h5" }}
					/>
					<Button
					variant="contained"
					color="primary"
					onClick={() => setNewEntryOpen(true)}
					className={clsx("addEntryBtn")}
					>
						Add
					</Button>
				</ListItem>
				<Divider key={`Divider-1`} />
				{
					newEntryOpen &&
					<>
						<ListItem key={"NewEntry"} className={clsx("newEntryItem", classes.newEntryItem)}>
							<TextField
								value={newEntry}
								onChange={e => handleEntryChange(e)}
								className={clsx("newEntryField")}
								inputProps={{
									"data-testid": "newEntryField",
								}}
							/>
							<div>
								<IconButton
									variant="contained"
									color="primary"
									disabled={isSaving}
									onClick={e => handleEntrySave()}
									className={clsx("saveNewEntryBtn")}
									data-testid="saveNewEntryBtn">
									<SaveIcon />
								</IconButton>
								<IconButton
								variant="contained"
								color="secondary"
								onClick={e => handleEntryCancel()}
								className={clsx("cancelNewEntryBtn")}
								data-testid="cancelNewEntryBtn">
									<CancelIcon />
								</IconButton>
							</div>
						</ListItem>
						<Divider key={`Divider-2`} />
					</>
				}
				{
					passThruEntries.map((e,i) => (
						<>
							<ListItem className={clsx("passThruEntry", classes.passThruEntry) } data-id={e.passThruName} key={e.passThruName}>
									<ListItemText
										classes={{ primary: classes.entry }}
										primary={e.passThruName}
										primaryTypographyProps={{ style: { fontSize: "1.25rem" } }}
									/>

								<IconButton
									onClick={ev => handleDelete(e.passThruId)}
									className={clsx("deleteEntryBtn")}
									data-id={e.passThruName}
									data-testid="deleteEntryBtn"
								>
									<DeleteIcon color="secondary" fontSize="large" />
								</IconButton>
							</ListItem>
							<Divider key={`Divider-${e+3}`} />
						</>
					))
				}

			</List>
		</Drawer>
	)
}


export default PassThruMappingDrawer;