import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
  reverseLaneNameLabel: {
    fontSize: 'large',
    marginTop: theme.spacing(3),
  },
  reverseLaneDivider: {
    marginBottom: '15px'
  },
  reverseLaneGrid: {
    width: "100%",
    margin: theme.spacing(1)
  },
  reverseLaneBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  reverseLaneLine: {
    marginTop: theme.spacing(1),
  },
  reverseLaneDrawer: {
    display: "inline-block",
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  editBtn: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    float: "right",
    right: '10px',
  },
  deleteBtn: {
    margin: theme.spacing(2),
    float: "right",
    right: '10px',
  },
  cancelBtn: {
    margin: theme.spacing(2),
    float: "right",
    right: '10px',
  },
  reverseLaneDividerFixed: {
    marginBottom: '10px',
    marginRight: '20px',
    marginLeft: '20px',
  },
  drawerDetailConfiguration: {
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  headerContainerSpacing: {
    marginTop: '15px'
  },
  addButton: {
    float: "right",
  },
}));
