import React, { useState } from "react";
import { useFormik } from "formik";
import useFormHandlers from "../useFormHandlers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "../styles";
import * as Yup from "yup";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Tooltip,
  TextField,
} from "@material-ui/core";

export const onlineValidationLimitPerTicketSchema = Yup.object().shape({
  onlinevalidationlimitperticketenabled: Yup.bool(),
  onlinevalidationlimitperticket: Yup.number()
    .typeError('Value must be a number')
    .min(1, "Value must be from 1 to 100")
    .max(100, "Value must be from 1 to 100")
    .required("Required"),
});

const FacilityValidationsSettingsForm = ({
  entityID,
  entityType,
  settingValues,
  disabled,
}) => {
  const classes = useStyles();
  const [facilityValidationsExpanded, setFacilityValidationsExpanded] = useState(true);
  const toggleFacilityValidationsExpanded = () => {
    setFacilityValidationsExpanded(!facilityValidationsExpanded);
  };

  const {
    values,
    setFieldValue,
    initialValues,
    errors,
    handleChange
  } = useFormik({
    initialValues: {
      onlinevalidationlimitperticketenabled: settingValues.onlinevalidationlimitperticketenabled,
      onlinevalidationlimitperticket: settingValues.onlinevalidationlimitperticket,
    },
    validationSchema: onlineValidationLimitPerTicketSchema
  });

  const {
    handleToggle,
    handleInputChange,
  } = useFormHandlers(setFieldValue, initialValues, entityID, entityType);

  return (
    <Grid 
    container 
    className={classes.panelRoot}
    data-testid="facility-validations-panel"
    >
      <Accordion
        data-tag="facilityValidations"
        expanded={facilityValidationsExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleFacilityValidationsExpanded}
        >
          <Grid xs={12}>
            <Typography className={classes.heading}>
              Facility Validations
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {!disabled && (
              <>
              <Grid
                data-tag="onlinevalidationlimitperticketenabled"
                item
                xs={12}
              >
                <FormControlLabel
                  role="online-validation-limit-toggle"
                  label="Enable Online Validation Limit"
                  control={
                    <Switch
                      name="onlinevalidationlimitperticketenabled"
                      color="primary"
                      onChange={e => handleToggle(values, errors, e)}
                      checked={values.onlinevalidationlimitperticketenabled}
                      data-checked={values.onlinevalidationlimitperticketenabled} 
                      inputProps={{ 'aria-label': 'onlinevalidationLimitSwitch' }}
                    />}
                />
              </Grid>
              <Grid
                data-tag="onlinevalidationlimitperticket"
                item
                xs={12}
                sm={6}
                md={4}
              > 
                  <Tooltip
                    placement="top-end"
                    title="Allows you to limit the online validations being applied to a ticket facility wide from 1 to 100"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Online Validation Limit"
                      name="onlinevalidationlimitperticket"
                      type="text"
                      disabled={!values.onlinevalidationlimitperticketenabled}
                      value={values.onlinevalidationlimitperticket}
                      onChange={handleChange}
                      onBlur={e => handleInputChange(errors, e)}
                      error={Boolean(errors.onlinevalidationlimitperticket)}
                      helperText={errors.onlinevalidationlimitperticket || "per ticket"}
                      FormHelperTextProps={{
                        'data-testid': 'onlinevalidationLimit-helpertext'
                      }}
                      InputProps={{
                        'data-testid': 'onlinevalidationLimitTextField',
                      }}
                    />
                    </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

FacilityValidationsSettingsForm.defaultProps = {
  settingValues: {},
};

export default FacilityValidationsSettingsForm;