import { useState } from "react";


export const useValidatorForm = (maintenanceWindow) => {
    const [isValidDuration, setValidDuration] = useState(true);
    const [isValidDay, setValidDay] = useState(true);

    const validate = (day, duration) => {
        setValidDuration(duration !== '');
        setValidDay(day !== '');
    };

    return { 
        isValidDuration,
        isValidDay,
        validate
    };
};