import React, { useState } from "react";

/* Components */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  Switch,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsResetButton from "../SettingsResetButton";
import { RemoveRedEye } from "@material-ui/icons";

/* Form */
import { useForm, Controller } from "react-hook-form";
import useFormHandlers from "../useFormHandlers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSettingDispatchChange } from "../index";

/* State */
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

/* Utilities */
import * as Yup from "yup";
import _ from "lodash";
import { useFlags } from "launchdarkly-react-client-sdk";

/* Style */
import { useStyles } from "./styles";
import { useStyles as useRootStyles } from "../styles";

/* Enum */
import { INTERCOM_PROVIDER } from "../../../../constants";

const IntercomProviderSettingsValidation = Yup.object().shape({
  intercomprovider: Yup.string(),
  parkerid: Yup.string().required("Required"),
  parkerpassword: Yup.string().required("Required"),
});

const IntercomProviderSettingsForm = ({
  entityID,
  entityType,
  settingValues,
  settingValuesParent,
}) => {
  const classes = useStyles();
  const rootClasses = useRootStyles();
  const [intercomProviderExpanded, setIntercomProviderExpanded] = useState(
    true
  );
  const enqueueSnackbar = useEnqueueSnackbar();
  const { dispatchSetting } = useSettingDispatchChange();
  const [parkerPasswordToggle, setparkerPasswordToggle] = useState(false);
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(IntercomProviderSettingsValidation),
    defaultValues: {
      intercomprovider: settingValues?.intercomprovider ?? "",
      parkerid: settingValues?.parker_user ?? "",
      parkerpassword: settingValues?.parker_password ?? "",
      intercom: {
        continuousring:
          settingValues?.continuousCallCenterNotification ?? false,
      },
    },
    mode: "onChange",
  });
  const { parkerIntercom } = useFlags();
  const watchFields = watch();

  const {
    handleToggle,
    handleSelectChange,
    handleResetChange,
    canRenderField,
  } = useFormHandlers(setValue, settingValues, entityID, entityType);

  const toggleIntercomProviderExpanded = () => {
    setIntercomProviderExpanded(!intercomProviderExpanded);
  };

  const handleContinuousRingChange = async (e) => {
    await handleToggle(
      {
        ["intercom.continuousring"]: !e.target.checked,
      },
      errors,
      e
    );
  };

  const toggleParkerPassword = () => {
    setparkerPasswordToggle((currentToggle) => !currentToggle);
  };

  const handleParkerIDChanged = async (e, errors) => {
    const targetValue = e.target.value;
    if (targetValue === settingValues.parker_user) return;
    if (errors.parkerid) {
      setValue(e.target.name, settingValues.parker_user, {
        shouldValidate: true,
      });
      return;
    }
    if (!(await dispatchSetting(entityID, "parker.user", targetValue)))
      enqueueSnackbar("Unable to set Parker User", {
        variant: "error",
        tag: "parkerUser",
      });
  };

  const handleParkerPasswordChanged = async (e, errors) => {
    const targetValue = e.target.value;
    if (targetValue === settingValues.parker_password) return;
    if (errors.parkerpassword) {
      setValue(e.target.name, settingValues.parker_password, {
        shouldValidate: true,
      });
      return;
    }
    if (!(await dispatchSetting(entityID, "parker.password", targetValue)))
      enqueueSnackbar("Unable to set Parker Password", {
        variant: "error",
        tag: "parkerPassword",
      });
  };

  const handleProviderSelect = async (e, selected) => {
    const { value } = e.target;
    setparkerPasswordToggle(false);
    if (!(await handleSelectChange(errors, e, selected))) return;
    // if the provider is not 'Amano' then turn the Continuous Ring Option off
    if (value != INTERCOM_PROVIDER.Amano)
      // handleToggle internally inverts the value
      handleToggle({ ["intercom.continuousring"]: true }, errors, {
        target: { name: "intercom.continuousring", value: true },
      });
  };

  return (
    <Grid className={rootClasses.panelRoot} container>
      <Accordion
        data-tag="intercom-provider"
        expanded={intercomProviderExpanded}
        TransitionProps={{ unmountOnExit: true }}
        className={rootClasses.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleIntercomProviderExpanded}
        >
          <Typography className={rootClasses.heading}>
            Intercom Provider
          </Typography>
          <Typography className={rootClasses.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          {canRenderField("intercomprovider") && (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                data-tag="intercom-provider"
                className={classes.intercomSettingContainer}
              >
                <Controller
                  name="intercomprovider"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={classes.intercomProviderSelector}
                      data-id="intercomprovider"
                      id="intercomprovider"
                      label={<Typography>Intercom Provider</Typography>}
                      select
                      variant="outlined"
                      onChange={handleProviderSelect}
                      inputProps={{
                        ["data-testid"]: "intercomprovider-select",
                      }}
                    >
                      <MenuItem value={INTERCOM_PROVIDER.Amano}>Amano</MenuItem>
                      <MenuItem value={INTERCOM_PROVIDER.External}>
                        External
                      </MenuItem>
                      {entityType?.toLowerCase() === "device" &&
                      parkerIntercom ? (
                        <MenuItem value={INTERCOM_PROVIDER.Parker}>
                          Parker
                        </MenuItem>
                      ) : (
                        <></>
                      )}
                    </TextField>
                  )}
                />
                <SettingsResetButton
                  entityID={entityID}
                  entityType={entityType}
                  settingName="intercomprovider"
                  currentValue={watchFields.intercomprovider}
                  originalValue={settingValuesParent.intercomprovider}
                  onClick={() => {
                    handleResetChange(
                      "intercomprovider",
                      settingValuesParent.intercomprovider
                    );
                  }}
                />
              </Grid>
              {watchFields.intercomprovider == INTERCOM_PROVIDER.Amano && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="intercom.continuousring"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        role="continuous-alert-toggle"
                        label="Continuous Alert Sound"
                        control={
                          <Switch
                            {...field}
                            id="intercom.continuousring"
                            data-testid="continuous-ring"
                            color="primary"
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              handleContinuousRingChange(e);
                            }}
                            checked={field.value}
                          />
                        }
                      />
                    )}
                  />
                  <SettingsResetButton
                    entityID={entityID}
                    entityType={entityType}
                    settingName="intercom.continuousring"
                    currentValue={watchFields.intercom.continuousring}
                    originalValue={
                      settingValuesParent.continuousCallCenterNotification
                    }
                    onClick={() => {
                      handleResetChange(
                        "intercom.continuousring",
                        settingValuesParent.continuousCallCenterNotification
                      );
                    }}
                  />
                </Grid>
              )}
              {watchFields.intercomprovider == INTERCOM_PROVIDER.Parker && (
                <Grid item container xs={12} sm={8} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="parkerid"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth role="intercom-parker-id">
                          <TextField
                            {...field}
                            id="parkerid"
                            data-testid="parkerid"
                            label="User"
                            onBlur={(e) => {
                              handleParkerIDChanged(e, errors);
                            }}
                            variant="outlined"
                            error={!!errors.parkerid}
                            helperText={
                              errors.parkerid && errors.parkerid.message
                            }
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="parkerpassword"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth role="intercom-parker-password">
                          <TextField
                            {...field}
                            id="parkerpassword"
                            data-testid="parkerpassword"
                            label="Password"
                            type={
                              parkerPasswordToggle === true
                                ? "text"
                                : "password"
                            }
                            InputProps={{
                              endAdornment: (
                                <RemoveRedEye
                                  className={classes.eye}
                                  onClick={toggleParkerPassword}
                                  data-testid="password-redeye"
                                />
                              ),
                            }}
                            onBlur={(e) => {
                              handleParkerPasswordChanged(e, errors);
                            }}
                            variant="outlined"
                            error={!!errors.parkerpassword}
                            helperText={
                              errors.parkerpassword &&
                              errors.parkerpassword.message
                            }
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default IntercomProviderSettingsForm;
