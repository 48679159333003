import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
  Grid,
  TextField,
	Button,
} from "@material-ui/core";

import PassThruMappingDrawer from './PassThruMappingDrawer.js';

import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import * as Yup from "yup";
import { useStyles } from "../styles";
import useFormHandlers from "../useFormHandlers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import RateService from "../../../../services/RateService";
import AccessGroupService from "../../../../services/AccessGroupService";
import apiClient from "../../../../auth/apiClient";
import useHasPermissions from "../../../../hooks/useHasPermissions";

const rateService = new RateService(apiClient);
const accessGroupSvc = new AccessGroupService(apiClient);

const ThirdPartyPassSettingsValidation = Yup.object().shape({
  thirdpartyaccessgroup: Yup.string().required(
    "An access group must be selected"
  ),
  thirdpartyrate: Yup.string().required("A rate must be selected"),
});

const ThirdPartyPassSettingsForm = ({
  entityID,
  entityType,
  settingValues,
  disabled,
}) => {
  const classes = useStyles();
  const [thirdPartyExpanded, setThirdPartyExpanded] = useState(true);
	const [passThruOpen, setPassThruOpen] = useState(false);
  const [rates, setRates] = useState([]);
  const [accessGroups, setAccessGroups] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const hasRatesView = hasPermissions(["rates.view"]);
  const hasAccessGroupsView = hasPermissions(["accessgroups.view"]);

  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || entityID);

  useEffect(() => {
    if (!hasRatesView) return;
    getAvailableRates();
  }, [entityID, scopeAwareFacilityID, hasRatesView]);

  useEffect(() => {
    if (!hasAccessGroupsView) return;
    getAccessGroups();
  }, [entityID, hasAccessGroupsView]);

  const getAvailableRates = useCallback(async () => {
    rateService
      .getAvailableRates(scopeAwareFacilityID)
      .then(result => {
        if (result !== null) {
          setRates(result.data);
        } else {
          enqueueSnackbar("No rates exist for this entity", {
            variant: "error",
            tag: "NoRatesExistForThisEntity",
          });
        }
      })
      .catch(err => {
        enqueueSnackbar("Unable to retrieve rates for this entity", {
          variant: "error",
          tag: "FailedToFindRatesForEntity",
        });
      });
  }, [entityID, scopeAwareFacilityID]);

  const getAccessGroups = useCallback(async () => {
    accessGroupSvc
      .GetAccessGroups(scopeAwareFacilityID)
      .then(result => {
        if (result !== null) {
          setAccessGroups(result.data.collection);
        } else {
          enqueueSnackbar("No access groups exist for this entity", {
            variant: "error",
            tag: "NoAccessGroupsExistForThisEntity",
          });
        }
      })
      .catch(err => {
        enqueueSnackbar("Unable to retrieve access groups for this entity", {
          variant: "error",
          tag: "FailedToFindAccessGroups",
        });
      });
  }, [entityID]);

  const {
    values,
    setFieldValue,
    initialValues,
    errors,
    handleChange,
  } = useFormik({
    initialValues: settingValues,
    validationSchema: ThirdPartyPassSettingsValidation,
  });

  const { handleSelectChange, canRenderField } = useFormHandlers(
    setFieldValue,
    initialValues,
    entityID,
    entityType
  );

  const toggleThirdPartyExpanded = () => {
    setThirdPartyExpanded(!thirdPartyExpanded);
  };

  return (
    <Grid className={classes.panelRoot} container>
      <Accordion
        data-tag="third-party"
        expanded={thirdPartyExpanded}
        TransitionProps={{ unmountOnExit: true }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleThirdPartyExpanded}
        >
          <Typography className={classes.heading}>Pass Rules</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              data-tag="access-group"
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <TextField
                data-id="thirdpartyaccessgroup"
                id="thirdpartyaccessgroup"
                name="thirdpartyaccessgroup"
                label="Access Group"
                select
                variant="outlined"
                SelectProps={{
                  SelectDisplayProps: {
                    "data-testid": "thirdpartyaccessgroup",
                  },
                }}
                fullWidth
                onChange={(e, selected) =>
                  handleSelectChange(errors, e, selected)
                }
                helperText={
                  (errors && errors["thirdpartyaccessgroup"]) ||
                  "access group used for third party passes"
                }
                defaultValue={values["thirdpartyaccessgroup"] ?? ""}
                value={values["thirdpartyaccessgroup"] ?? ""}
                error={errors && errors["thirdpartyaccessgroup"] ? true : false}
              >
                {accessGroups != null &&
                  accessGroups.map(row => (
                    <MenuItem
                      key={row.accessGroupID}
                      value={row.accessGroupID}
                      id={row.name}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid data-tag="rate" item xs={12} sm={4} md={4} lg={4} xl={4}>
              <TextField
                data-id="thirdpartyrate"
                id="thirdpartyrate"
                name="thirdpartyrate"
                label="Rate"
                select
                variant="outlined"
                SelectProps={{
                  SelectDisplayProps: { "data-testid": "thirdpartyrate" },
                }}
                fullWidth
                onChange={(e, selected) =>
                  handleSelectChange(errors, e, selected)
                }
                helperText={
                  (errors && errors["thirdpartyrate"]) ||
                  "rate used for third party passes"
                }
                defaultValue={values["thirdpartyrate"] ?? ""}
                value={values["thirdpartyrate"] ?? ""}
                error={errors && errors["thirdpartyrate"] ? true : false}
              >
                {rates != null &&
                  rates.map(row => (
                    <MenuItem key={row.rateID} value={row.rateID} id={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

						<Grid data-tag="passthru" item xs={12} sm={4} md={4} lg={4} xl={4}>
							<Button
								onClick={() => { setPassThruOpen(true) }}
								fullWidth
								variant="contained"
								data-testid="passthrubtn"
								type="submit"
								color="primary">
								Pass-Thru Mapping
							</Button>
							<PassThruMappingDrawer
								passThruOpen={passThruOpen}
								setPassThruOpen={setPassThruOpen}
								facilityId={scopeAwareFacilityID}
							/>
						</Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ThirdPartyPassSettingsForm.defaultProps = {
  disabled: false,
};

export default ThirdPartyPassSettingsForm;
