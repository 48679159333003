import React, { useRef, useState} from 'react'
import { useStyles } from '../MaintenanceWindowSettingStyles'
import { useValidatorForm } from './useValidatorForm';
import { UpdateWindowSelect } from './UpdateWindowSelect';
import { FormControl, Button, CircularProgress, Box} from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { DAYS_OF_WEEK } from '../../../../../constants/DaysOfWeek';

export const MaintenanceWindowForm = ({handleSubmit, maintenanceWindow, timeZone}) => {
    const initTimeVal = moment.tz(`2023-01-01 ${maintenanceWindow?.timeSpan ?? "03:00:00"}`, timeZone)
    const [startTimeVal, setTimeVal] = useState(initTimeVal);
    const [isSaving, setIsSaving] = useState(false);
    const classes = useStyles();

    const dayRef = useRef();
    const durationRef = useRef();
    const { isValidDay, isValidDuration, validate } = useValidatorForm(maintenanceWindow);
    const submitBtnLabel = isSaving ? "Saving" : "Submit";
    const handleTimeChange = (val) => {
        setTimeVal(val);
    }
    
    const onSubmit = async () => {
        const day = dayRef.current.value;
        const durations = durationRef.current.value;
        const startTime = moment(startTimeVal).format('HH:mm:00');
        console.log(day, durations, startTime);
        console.log(maintenanceWindow);
        if(maintenanceWindow && 
            day === maintenanceWindow.startDay & 
            durations === maintenanceWindow.duration & 
            startTime === maintenanceWindow.timeSpan 
        ) return;
        setIsSaving(true);

        validate(day, durations);

        if(day === '' || durations == '') {
            setIsSaving(false);
            return;
        }
        await handleSubmit(day, durations, startTime);
        setIsSaving(false);
    };
    return (
        <div className={classes["update-window-form-wrapper"]}>  
            <UpdateWindowSelect
                menuItems={DAYS_OF_WEEK}
                labelId="update-window-start-day-slct-lbl"
                selectId="update-window-start-day-slct"
                label="Day"
                inputRef={dayRef}
                error={!isValidDay}
                errorMessage="Day is required"
                selectedValue={maintenanceWindow ? maintenanceWindow.startDay : ''}
            />
            <FormControl className={classes["update-window-form-controls"]}>
                <TimePicker 
                    id='time' 
                    label="Start Time" 
                    value={startTimeVal ? moment(startTimeVal).tz(timeZone, false) : null} 
                    onChange={(val)=> handleTimeChange(val)}/>
            </FormControl>
            <UpdateWindowSelect
                menuItems={DURATION}
                labelId="update-window-start-duration-slct-lbl"
                selectId="update-window-start-duration-slct"
                label="Duration"
                inputRef={durationRef}
                error={!isValidDuration}
                errorMessage="Duration is required"
                selectedValue={maintenanceWindow ? maintenanceWindow.duration : ''}
            />
            <Box className={classes["btn-wrapper"]}>
                <Button 
                    disabled={isSaving} 
                    color="primary"               
                    className={classes["update-window-btn"]} 
                    onClick={onSubmit} 
                    data-testid="submit-btn"
                >
                    {submitBtnLabel}
                </Button>
                {isSaving && (
                <CircularProgress
                    size={15}
                    className={classes["update-window-btn-loader"]}
                />
                )}
            </Box>
        </div>
    )
}

const DURATION = [
    {
        label: "0.5 hours",
        value: 0.5
    },
    {
        label: "1 hours",
        value: 1
    },
    {
        label: "1.5 hours",
        value: 1.5
    },
    {
        label: "2 hours",
        value: 2
    }
]