import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Drawer,
  Typography
} from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import PropTypes from "prop-types";
import Title from "../../Title";
import { useStyles } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEngineWarning } from "@fortawesome/pro-duotone-svg-icons";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import ReverseLaneForm from "./ReverseLaneSettings";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import ReversingLaneService from "../../../services/ReversingLaneService";

import apiClient from "../../../auth/apiClient";

import { useSelector } from "react-redux";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
const reversingLaneService = new ReversingLaneService(apiClient);

const ReverseLane = ({ entityID, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [reverseLaneDetail, setReverseLaneDetail] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const { textConfirmation } = useConfirmationDialog();

  const [laneAdded, setLaneAdded] = useState([]);

  useEffect(() => {
    if (facilityID != null) {
      getReversingLanes();
    }
  }, [facilityID], [laneAdded]);

  const getReversingLanes = async () => {
    try {
      var result = await reversingLaneService.getReversingLanes(facilityID);
      setReversingLanes(result.data ?? []);
    } catch (err) {
      enqueueSnackbar("Unable to retrieve reversing lanes for this entity", {
        variant: "error",
        tag: "FailedToFindReversingLanesForEntity",
      });
    }
  };
  const [reversingLanes, setReversingLanes] = useState([]);
  const defaultReverseLaneDetail = {
    laneID: undefined,
    laneName: "",
    entryDevice: "",
    exitDevice: "",
    reversingTimeOut: 10,
    entityID: entityID,
    settings: {}
  };

  const [drawerState, setDrawerState] = useState(false);

  const createReverseLane = async (objToUpdate) => {
    try {
      var result = await reversingLaneService.createReverseLane(facilityID, objToUpdate);
      setLaneAdded(result.data);
      getReversingLanes();
      setDrawerState(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to create reverse lane", {
        variant: "error",
        tag: "FailedToCreateReverseLane"
      });
    }
  };

  const updateReverseLane = async (objToUpdate) => {
    try {
      var result = await reversingLaneService.updateReverseLane(facilityID, objToUpdate);
      setLaneAdded(result.data);
      getReversingLanes();
      setDrawerState(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update reverse lane", {
        variant: "error",
        tag: "FailedToUpdateReverseLane"
      });
    }
  };

  const deleteReverseLane = async (objToDelete) => {
    const msgComponent = (
      <>
        <FontAwesomeIcon
          icon={faEngineWarning}
          size="4x"
          className={clsx(["animate__animated", "animate__tada"])}
          style={{
            color: theme.palette.error.main,
            float: "left",
            alignItems: "baseline",
            marginRight: 15,
          }}
        ></FontAwesomeIcon>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", color: theme.palette.error.main }}
        >
          This is a permanent action that will delete the reversing lane and cannot be undone.
        </Typography>
        <div style={{ clear: "both" }}></div>
      </>
    );
    let continueDelete = await textConfirmation({
      title: `DELETE ${objToDelete.laneName.toUpperCase()}?`,
      message: msgComponent,
      textConfirmation: objToDelete.laneName.toUpperCase(),
      buttons: [
        { name: "Yes", color: "secondary" },
        { name: "Cancel", color: theme.palette.text.disabled },
      ],
    });

    if (!continueDelete) return;
    try {
      await reversingLaneService.deleteReverseLane(facilityID, objToDelete.laneID);
      setDrawerState(false);
      getReversingLanes();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete reverse lane", {
        variant: "error",
        tag: "FailedToDeleteReverseLane"
      });
    }
  };

  const handleSelected = async (reverseLane) => {
    setDrawerState(true);
    reverseLane.entryDevice = reverseLane?.laneDevices.find((x) => x.mode == 1)?.deviceID ?? "";
    reverseLane.exitDevice = reverseLane?.laneDevices.find((x) => x.mode == 2)?.deviceID ?? "";
    setReverseLaneDetail(reverseLane);
  };

  const onAddClick = () => {
    setDrawerState(true);
    setReverseLaneDetail(defaultReverseLaneDetail);
  }

  const closeDrawer = () => {
    setDrawerState(false);
  };

  return (
    <div className={classes.reverseLaneDrawer}>
      <Box className={classes.reverseLaneBox}>
        <Grid container item xs={12} sm={12} className={clsx([classes.headerContainerSpacing])}>
          <Grid item xs={8} sm={8}>
            <Title>Reversing Lanes</Title>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button
              className={clsx("btn-add", classes.addButton)}
              data-id="add-btn"
              data-testid="add-btn"
              variant="contained"
              color="primary"
              onClick={onAddClick}
            >
              {"Add"}
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.reverseLaneDivider} />
        <Grid container className={classes.reverseLaneGrid}>
          {reversingLanes.sort((a, b) => a.laneName.localeCompare(b.laneName))
            .map((row) => (
              <Grid container item xs={12} lg={12} className={clsx([classes.reverseLaneLine, "reversing-lanes", row.laneName])} key={row.laneID} data-id={row.laneID}>
                <Grid item xs={8} lg={8} className={clsx(classes.reverseLaneNameLabel)}>
                  {row.laneName}
                </Grid>
                <Grid data-tag="activeGrid" item xs={2} lg={2}>
                  <div className={clsx(classes.editBtn)}>
                    <Tooltip title={"Edit Lanes"}>
                      <Button
                        data-testid="edit-button"
                        color="primary"
                        variant="contained"
                        onClick={(e) =>
                          handleSelected(row)
                        }
                      >
                        Edit
                      </Button>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid data-tag="activeGrid" item xs={2} lg={2}>
                  <div className={clsx(classes.deleteBtn)}>
                    <Tooltip title={"Delete Lanes"}>
                      <IconButton
                        data-testid="delete-button"
                        color="primary"
                        variant="contained"
                        onClick={(e) =>
                          deleteReverseLane(row)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>

              </Grid>
            )
            )}
        </Grid>
      </Box>

      <div>
        <Divider className={classes.reverseLaneDividerFixed} />
      </div>
      <Button
        className={clsx("reverseLane-cancel", classes.cancelBtn)}
        variant="contained"
        data-id="closeBtn"
        onClick={onClose}
      >
        Close
      </Button>
      <Drawer
        className={clsx("drawer", "reverseLane-config")}
        anchor="right"
        open={drawerState}
        classes={{
          paper: classes.drawerDetailConfiguration,
        }}
        onClose={closeDrawer}
      >
        <ReverseLaneForm
          handleSave={createReverseLane}
          handleEdit={updateReverseLane}
          handleCancel={closeDrawer}
          reverseLane={reverseLaneDetail}
        />
      </Drawer>
    </div>
  );
}

ReverseLane.defaultProps = {
  entityID: null,
  onClose: () => { },
};

ReverseLane.propTypes = {
  entityID: PropTypes.string,
  onClose: PropTypes.func,
};

export default ReverseLane;