import React, { useState } from "react";

/* Components */
import { Button } from "@material-ui/core";
import StyledMenu from "../../../Modals/StyledMenu";
import SortableList from "../../../List/Sortable";

/* Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownWideShort } from "@fortawesome/pro-regular-svg-icons";

const PayOnEntrySortMenuButton = ({ onSort, items }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuButtonClick = (event) => {
    setIsDialogOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button
        data-testid="pay-on-entry-sort-menu-btn"
        startIcon={
          <FontAwesomeIcon
            icon={faArrowDownWideShort}
            title="Sort Offers"
          ></FontAwesomeIcon>
        }
        onClick={onMenuButtonClick}
      ></Button>
      <StyledMenu
        id="pay-on-entry-sort-menu"
        data-testid="pay-on-entry-sort-menu"
        anchorEl={anchorEl}
        open={isDialogOpen}
        onClose={handleClose}
      >
        <SortableList
          identifier={"poe-sorter"}
          label="Pay on Entry Sort"
          items={items}
          onSort={onSort}
        />
      </StyledMenu>
    </>
  );
};

export default PayOnEntrySortMenuButton;
