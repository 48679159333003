import React, {useState, useMemo} from 'react'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "../MaintenanceWindowSettingStyles";
import PropTypes from "prop-types";

export const UpdateWindowSelect = (props) => {
    const {
        menuItems,
        labelId,
        selectId,
        label,
        inputRef,
        selectedValue,
        error
    } = props;


    const classes = useStyles();
    const [value, setValue] = useState(selectedValue);

    const onValueChange = (event) => {
        setValue(event.target.value);
    }

    const memoizedMenuItems = useMemo(() => (
        menuItems.map((items) => (
            <MenuItem key={items.value} value={items.value}>
                {items.label}
            </MenuItem>
        ))
    ), [menuItems]);

    return (
        <FormControl className={classes["update-window-form-controls"]} error={error}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
            labelId={labelId}
            id={selectId}
            value={value}
            onChange={onValueChange}
            inputRef={inputRef}
            required={true}
            >
                {memoizedMenuItems}
            </Select>
        </FormControl>
    )
}

UpdateWindowSelect.propTypes = {
    menuItems: PropTypes.array.isRequired,
    labelId: PropTypes.string.isRequired,
    selectId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputRef: PropTypes.object,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.bool
};
