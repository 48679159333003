import React, { useState } from "react";

import clsx from "clsx";

/* Material UI */
import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";

/* Style */
import { useStyles } from "../styles";

import TicketFieldService from "../../../../services/TicketFieldService";
import apiClient from "../../../../auth/apiClient";

const ticketFieldService = new TicketFieldService(apiClient);

const TicketFieldsSettingsForm = ({
  requestType,
  onTicketFieldsButtonClicked,
}) => {

  const requestName = ticketFieldService.getRequestName(requestType);
  const classes = useStyles();
  const [ticketFieldsSettingsExpanded, setTicketFieldsSettingsExpanded] = useState(true);
  const toggleTicketFieldsSettingsExpanded = () => {
    setTicketFieldsSettingsExpanded(!ticketFieldsSettingsExpanded);
  };

  return (
    <Grid
      container
      className={classes.panelRoot}
      data-testid="ticket-fields-panel"
    >
      <Accordion
        data-tag="ticketFieldSettings"
        expanded={ticketFieldsSettingsExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleTicketFieldsSettingsExpanded}
        >
          <Typography className={classes.heading}>
            {requestName}
          </Typography>
        </AccordionSummary>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} style={{ marginBottom: '10px', marginLeft: '15px' }}>
          <Button
            className={clsx(["TicketFieldsBtn"])}
            data-testid="ticketfieldsBtn"
            variant="contained"
            fullWidth
            onClick={onTicketFieldsButtonClicked}
            color="primary"
          >
            {requestName} Ticket Fields
          </Button>
        </Grid>
      </Accordion>
    </Grid>
  );
};

TicketFieldsSettingsForm.defaultProps = {
  requestType: 1,
};

TicketFieldsSettingsForm.propTypes = {
  requestType: PropTypes.number,
  onTicketFieldsButtonClicked: PropTypes.func.isRequired,
};

export default TicketFieldsSettingsForm;