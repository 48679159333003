import React from "react";
import {
	Grid,
	Button,
	Divider,
	Typography,
	FormControl,
	Drawer,
	Box
} from "@material-ui/core";
import Title from "../../../Title";
import _ from "lodash";
import { useStyles } from "./TicketLessExceptioFeeDrawer.styles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DurationEntry from "../../../Rate/Inputs/DurationEntry";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { useSettingDispatchChange } from "../index";
import PropTypes from "prop-types";
import { propTypes } from "react-json-pretty";
import SettingsResetButton from "../SettingsResetButton";
import useFormHandlers from "../useFormHandlers";

export const ticketLessExceptionFeeSchema = Yup.object().shape({
	lpr:
		Yup.object().shape({
			ticketlessexceptionfeeduration: Yup.number()
				.typeError("Must be a positive number")
				.positive("Must be a positive number")
				.min(0, "Must be a positive number")
				.max(31536000, "Must be less than 12 months")
				.required("Required")
		})
});

const TicketLessExceptionFeeDrawer = ({
	drawerOpen, setDrawerOpen, entityID, settingValues, entityType, settingValuesParent
}) => {
	const classes = useStyles();
	const enqueueSnackbar = useEnqueueSnackbar();
	const { dispatchSetting } = useSettingDispatchChange();

	const {
		control,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			lpr: {
				ticketlessexceptionfeeduration: settingValues?.lprTicketLessExceptionFeeDuration ?? 0
			}
		},
		resolver: yupResolver(ticketLessExceptionFeeSchema),
		shouldUnregister: false,
	});

	const watchFields = watch();

	const { handleResetChange } = useFormHandlers(
		setValue,
		settingValues,
		entityID,
		entityType
	);

	const onSubmit = async (data) => {
		if (!(await dispatchSetting(entityID, "lpr.ticketlessexceptionfeeduration", data.lpr.ticketlessexceptionfeeduration))) {
			enqueueSnackbar("Unable to set ticketless exception fee duration", {
				variant: "error",
				tag: "lprTicketlessExceptionFeeDuration",
			});
		} else {
			setDrawerOpen(false);
		}
	};

	const onClose = () => {
		resetLprduration();
		setDrawerOpen(false);
	}
	
	const resetLprduration = () => {
		reset({
			lpr: {
				ticketlessexceptionfeeduration: settingValues?.lprTicketLessExceptionFeeDuration ?? 0
			}
		});
	}

	return (
		<Drawer
			classes={{ paper: classes.ticketlessFeeDrawer }}
			anchor="right"
			open={drawerOpen}
			onClose={() => onClose()}>
			<Grid container>
				<Grid item xs={12} md={12}>
					<Typography className={clsx([classes.ticketlessFeeTitle])}>
						<Title>
							Ticketless Exception Fee
						</Title>
					</Typography>
					<Divider></Divider>
					<Grid item xs={12} md={12} className={clsx(classes.headerDesc)}>
						<span>
							The exit lane will use the configured lost ticket rate for fee calculation. Enter the amount of time to use for that calculation.
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Box className={clsx([classes.ticketlessLPRBox])}>
				<Grid container className={clsx([classes.drawerContainer])}>
					<Grid item xs={12} sm={12} className={clsx([classes.drawerItems], [classes.resetButton])}>
						<Controller
							name="lpr.ticketlessexceptionfeeduration"
							control={control}
							render={({ field }) => (
								<FormControl fullWidth role="exception-fee-duration">
									<DurationEntry
										label={"Duration"}
										name="lpr.ticketlessexceptionfeeduration"
										id="ticketlessexceptionfeeduration"
										value={field?.value}
										onChange={(name, seconds) => {
											field.onChange(seconds);
										}}
										variant="outlined"
									/>
								</FormControl>
							)}
						/>
						{errors.lpr?.ticketlessexceptionfeeduration &&
							(watchFields.lprTicketLessExceptionFeeDuration != settingValuesParent?.lprTicketLessExceptionFeeDuration)
							&& (
								<div className={clsx([classes.selectError])}>
									{errors.lpr?.ticketlessexceptionfeeduration?.message}
								</div>
							)}
						{(settingValues?.lprTicketLessExceptionFeeDuration != settingValuesParent?.lprTicketLessExceptionFeeDuration) &&
							(<SettingsResetButton
								entityID={entityID}
								entityType={entityType}
								settingName="lpr.ticketlessexceptionfeeduration"
								currentValue={watchFields.lpr.ticketlessexceptionfeeduration}
								originalValue={settingValuesParent.lprTicketLessExceptionFeeDuration}
								onClick={() => {
									resetLprduration();
									handleResetChange(
										"lpr.ticketlessexceptionfeeduration",
										settingValuesParent.lprTicketLessExceptionFeeDuration
									);
								}}
							/>)
						}
					</Grid>
				</Grid>
			</Box>
			<Box>
				<Divider></Divider>
				<Grid container className={clsx([classes.footerContainer])}>
					<Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
						<Button
							className={clsx("cancelBtn", classes.btnControl)}
							name="cancel"
							variant="contained"
							onClick={() => onClose()}
							disabled={isSubmitting}>
							Cancel
						</Button>
						<Button
							color="primary"
							name="save"
							type="submit"
							variant="contained"
							className={clsx("saveBtn", classes.btnControl)}
							onClick={handleSubmit(onSubmit)}
							disabled={isSubmitting}>
							Save
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Drawer>
	);
};

TicketLessExceptionFeeDrawer.defaultProps = {
	drawerOpen: false,
	setDrawerOpen: () => { },
	entityID: null,
	settingValues: {},
	entityType: null,
	settingValuesParent: {}
};
TicketLessExceptionFeeDrawer.propTypes = {
	drawerOpen: PropTypes.bool,
	setDrawerOpen: PropTypes.func,
	entityID: PropTypes.string,
	settingValues: propTypes.object,
	entityType: propTypes.string,
	settingValuesParent: propTypes.object
};

export default TicketLessExceptionFeeDrawer;
