import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useForm, Controller } from 'react-hook-form';
import * as Yup from "yup";
import clsx from "clsx";
import { TimePicker } from "@material-ui/pickers";
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from "prop-types";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import ScheduleReportService from "../../../../services/ScheduleReportService";
import apiClient from "../../../../auth/apiClient";

const scheduleReportService = new ScheduleReportService(apiClient);

const daysOfWeek = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

const ScheduleReportConfigurationSchema = Yup.object().shape({
  time: Yup.string()
    .required("Day is required")
    .nullable(),
  day: Yup.string()
    .oneOf(daysOfWeek.map(day => day.value))
    .required('Day is required'),
});

const ScheduleReportConfigurationForm = ({
  identifier,
  entityID,
}) => {
  const classes = useStyles();

  const [scheduleReportExpanded, setScheduleReportExpanded] = useState(true);
  const toggleScheduleReportExpanded = () => {
    setScheduleReportExpanded(!scheduleReportExpanded);
  };

  const enqueueSnackbar = useEnqueueSnackbar();

  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const handleTimePickerOpen = () => {
    setIsTimePickerOpen(true);
  };
  const handleTimePickerClose = () => {
    setIsTimePickerOpen(false);
  };

  const [isMenuItemOpen, setIsMenuItemOpen] = useState(false);
  const handleMenuItemOpen = () => {
    setIsMenuItemOpen(true);
  };
  const handleMenuItemClose = () => {
    setIsMenuItemOpen(false);
  };

  let StartOfWeek = null;
  let formattedTime = null;

  const { control, getValues, setValue } = useForm({
    defaultValues: {
        entityID: entityID,
        time: null,
        day: ""
    },
    validationSchema: ScheduleReportConfigurationSchema,
    mode: 'onChange'
  });


  useEffect(() => {
    const getSchedule = async () => {
      try {
        const result = await scheduleReportService.getStartOfDayWeek(entityID);
        const { StartOfDay, StartOfWeek: startOfWeek } = JSON.parse(result.data.payload);
        const formattedTime = new Date(`2024-01-01T${StartOfDay}`);
        setValue('time', formattedTime);
        setValue('day', startOfWeek);
      } catch (error) {
        console.error('Error fetching schedule:', error);
        enqueueSnackbar("Failed to retrieve the schedule", {
          variant: "error"
        });
      }
    };

    getSchedule();
  }, []);

  const createStartOfDayWeek = (newValues) => {
    const time = new Date(newValues.time)
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    return {
      entityid: entityID,
      Payload: JSON.stringify({
        StartOfDay: `${hours}:${minutes}`,
        StartOfWeek: newValues.day
      })
    };
  };

  const updateDatabase = async (newValues) => {
      try {
        const result = await scheduleReportService.addStartOfDayWeek(createStartOfDayWeek(newValues));
        const { StartOfDay, StartOfWeek: startOfWeek } = JSON.parse(result.data.payload);
        setValue('time', new Date(`2024-01-01T${StartOfDay}`));
        setValue('day', startOfWeek);
      } catch (error) {
        console.error('Error updating database:', error);
        enqueueSnackbar("Failed to update the schedule.  Please try again.", {
          variant: "error"
        });
      }
  };

  return (
    <Grid className={clsx("schedule-report-panel", classes.panelRoot)} container data-testid={identifier}>
      <Accordion
        data-tag="scheduleReport"
        expanded={scheduleReportExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={clsx("accordion", classes.fullWidthAccordion)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleScheduleReportExpanded}
        >
          <Typography className={clsx("heading", classes.heading)}>
          Schedule Report Start of Day/Week
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs = {12}>
              <Tooltip
                placement="top-start"
                title="Configures your default ‘From’ time when creating a scheduled report"
                disableHoverListener={isTimePickerOpen}
              >
                <Grid
                  item
                  xs={4}
                  sm={3}
                  className={clsx(classes.scheduleReportStartOfDay)}
                >
                  <Controller
                    name="time"
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        {...field}
                        label="Start of Day"
                        value={field.value || null}
                        onChange={(newValue) => {
                          updateDatabase({ ...getValues(), time: newValue });
                        }}
                        onOpen={handleTimePickerOpen}
                        onClose={handleTimePickerClose}
                        data-testid="start-of-day-time-picker"
                      />
                    )}
                  />
                </Grid>
              </Tooltip>
              <Tooltip
                placement="top-start"
                title="Configures the default day for the start of the week when creating a weekly scheduled report"
                disableHoverListener={isMenuItemOpen}
              >
                <Grid
                  item
                  xs={12}
                  sm={9}
                >
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="day-selector-label">Day of The Week</InputLabel>
                    <Controller
                      name="day"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="day-selector-label"
                          id="day-selector"
                          label="Day of The Week"
                          value={field.value ?? ''}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            updateDatabase({ ...getValues(), day: newValue });
                          }}
                          onOpen={handleMenuItemOpen}
                          onClose={handleMenuItemClose}
                        >
                          {daysOfWeek.map((day) => (
                            <MenuItem key={day.value} value={day.value}>
                              {day.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ScheduleReportConfigurationForm.defaultProps = {
  entityID: "",
  entityType: "",
  settingValues: {},
};

ScheduleReportConfigurationForm.propTypes = {
  entityID: PropTypes.string,
  entityType: PropTypes.string,
  settingValues: PropTypes.any
}

export default ScheduleReportConfigurationForm;
