import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    "update-window-form-header": {
        display: "flex",
        justifyContent: "felx-start",
        gap: "1px",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: theme.spacing(2),
        "& h6": {
            fontSize: "1.15rem",
            color: theme.palette.primary.main,
        },
        "& svg": {
            marginBottom: "0.1rem",
        }
      },
    "update-window-form-container":{
        width: "100%"
    },
    "update-window-form-wrapper":{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        flexWrap: "wrap",
        gap: "1rem",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
        "& button": {
            alignItems: "flex-end",
            padding: 0,
        },
    },
    "update-window-form-controls": {
        minWidth: "10%",
    },
    "update-window-btn": {
        height: "1.9rem",
    },
    "btn-wrapper": {
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    "update-window-btn-loader": {
        position: "absolute",
        top: "32%",
        left: "97%",
    },
}));
